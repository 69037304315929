/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Tooltip from '@mui/material/Tooltip'
import {
  capitalizeFirstLetter,
  getStoneImage,
  getStoneTypeColor,
} from 'helpers/utils'
import Images from 'images'
import { IShoe, ISocket } from 'types/nft'
import styles from './Sockets.styles'

interface Props {
  shoeDetail: IShoe
}

interface SocketSlotProps {
  shoeDetail: IShoe
  socket: ISocket
  idx: number
}

const Sockets = ({ shoeDetail }: Props) => {
  const sockets = shoeDetail.sockets

  if (!sockets?.length) {
    return null
  }

  return (
    <div>
      {sockets.map((item, i) => (
        <SocketSlot shoeDetail={shoeDetail} socket={item} idx={i} key={i} />
      ))}
    </div>
  )
}

const SocketSlot = ({ shoeDetail, socket, idx, ...props }: SocketSlotProps) => {
  let position = styles.socket1

  if (idx === 1) position = styles.socket2
  if (idx === 2) position = styles.socket3
  if (idx === 3) position = styles.socket4

  const typeText = capitalizeFirstLetter(socket.socketType)
  const img = (Images.SocketsLock as any)[typeText]
  const typeColor = getStoneTypeColor(typeText)

  // // if (!socket.unlock) {
  // if (socket.socketType === 'RECOVERY')
  //   img = Images.SocketsLock.Recovery
  // if (socket.socketType === 'SPEED') img = Images.SocketsLock.Speed
  // if (socket.socketType === 'LUCK') img = Images.SocketsLock.Luck
  // if (socket.socketType === 'PERFORMANCE')
  //   img = Images.SocketsLock.Recovery
  // // }

  const renderUnLock = () => {
    const UnLockImg = (Images.SocketUnLock as any)[
      capitalizeFirstLetter(socket.socketQuality)
    ]

    let stoneImg = null

    if (socket.stone) {
      stoneImg = getStoneImage(socket.stone.type, socket.stone.level)
    }

    return (
      <>
        {stoneImg && (
          <div css={styles.stoneImgWrap}>
            <img src={stoneImg} css={styles.stoneImg} alt='stone' />
          </div>
        )}
        <UnLockImg />
      </>
    )
  }

  return (
    <div
      css={[
        styles.socket,
        position,
        !socket.available && {
          filter: 'grayscale(1)',
        },
        socket.unlock &&
          css`
            path {
              fill: ${typeColor} !important;
            }
            circle {
              stroke: ${typeColor} !important;
            }
          `,
      ]}
    >
      {socket.unlock && renderUnLock()}
      {!socket.unlock && (
        <>
          {socket.available ? (
            <img src={img} alt='stone' />
          ) : (
            <Tooltip title={'Level ' + (idx + 1) * 5}>
              <img src={img} alt='stone' />
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
}

export default Sockets
