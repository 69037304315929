/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import _get from 'lodash/get'
import _set from 'lodash/set'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import { commonClass } from 'theme'
import { toFixedNumber, getShoeAttribute } from 'helpers/utils'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { IShoe } from 'types/nft'
import { addShoePoint } from 'store/reducers/bag'
import styles from './AddPointModal.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  shoeDetail: IShoe | null
  onSuccess: () => void
}

interface IAttr {
  label: 'Performance'
  key: 'performance'
  value: number
  value2: number
  iconStyle: {
    color: string
    backgroundColor: string
  }
  total: number
  addedPoint: number
  finalPoint: number
  minusDisabled: boolean
  plusDisabled: boolean
}

const AddPointModal = ({
  isOpen,
  handleClose,
  shoeDetail,
  onSuccess,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [addPoints, setAddPoints] = useState({
    performance: 0,
    recovery: 0,
    speed: 0,
    luck: 0,
  })
  const [currentPoint, setCurrentPoint] = useState(0)

  useEffect(() => {
    setCurrentPoint(_get(shoeDetail, 'point') || 0)
    setAddPoints({
      performance: 0,
      recovery: 0,
      speed: 0,
      luck: 0,
    })
  }, [shoeDetail, isOpen])
  useEffect(() => {
    if (!isOpen) {
      setLoading(false)
    }
  }, [isOpen])

  const handleConfirm = () => {
    setLoading(true)
    if (shoeDetail?.shoeId) {
      dispatch(
        addShoePoint({
          shoeId: shoeDetail.shoeId,
          ...addPoints,
        }),
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            onSuccess()
            handleClose()
          } else {
            dispatch(
              showToast({
                title: 'Error',
                message: response.message,
              }),
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
  const basePoint = getShoeAttribute(shoeDetail as IShoe)
    ?.properties as unknown as IAttr[]

  basePoint.forEach((curr) => {
    curr.total = (Number(curr.value) || 0) + Number(curr.value2) || 0
    curr.addedPoint = _get(addPoints, curr.key)
    curr.finalPoint = curr.total + curr.addedPoint
    curr.minusDisabled = curr.addedPoint === 0
    curr.plusDisabled = currentPoint === 0
  })

  const plusPoint = (attr: IAttr) => {
    if (currentPoint) {
      _set(addPoints, attr.key, _get(addPoints, attr.key) + 1)
      setAddPoints({ ...addPoints })
      setCurrentPoint(currentPoint - 1)
    }
  }

  const minusPoint = (attr: IAttr) => {
    const miP = _get(addPoints, attr.key)

    if (miP) {
      _set(addPoints, attr.key, miP - 1)
      setAddPoints({ ...addPoints })
      setCurrentPoint(currentPoint + 1)
    }
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={styles.wrapper}>
        <>
          <div className='df jcb'>
            <div css={styles.modalTitle}>Add points</div>
            <div className='pointer' onClick={handleClose}>
              <Images.CloseIcon />
            </div>
          </div>

          <div css={styles.avaiPointWrapper}>
            <div css={styles.currentPoint}>{currentPoint}</div>
            <span className='mt-2'>Available points</span>
          </div>

          <div css={styles.addPointWrapper}>
            {basePoint.map((attr) => {
              return (
                <div
                  key={attr.label}
                  css={styles.attrRow}
                  className='df aic jcb'
                >
                  <div className='df aic'>
                    <div
                      className='dfc'
                      css={[styles.attributeIcon, attr.iconStyle]}
                    >
                      <span css={[{ color: attr.iconStyle.color }]}>
                        {attr.label[0]}
                      </span>
                    </div>

                    <span>{attr.label}</span>
                  </div>
                  <div className='df aic'>
                    <Button
                      css={[
                        styles.pointAction,
                        attr.minusDisabled && styles.btnDisable,
                      ]}
                      disabled={attr.minusDisabled}
                      onClick={() => minusPoint(attr)}
                    >
                      <img src={Images.MinusWhite} alt='' />
                    </Button>
                    <span
                      css={[
                        styles.finalPoint,
                        attr.total === attr.finalPoint && styles.btnDisable,
                      ]}
                    >
                      {toFixedNumber(attr.finalPoint)}
                    </span>
                    <Button
                      css={[
                        styles.pointAction,
                        attr.plusDisabled && styles.btnDisable,
                      ]}
                      disabled={attr.plusDisabled}
                      onClick={() => plusPoint(attr)}
                    >
                      <img src={Images.PlusWhite} alt='' />
                    </Button>
                  </div>
                </div>
              )
            })}
          </div>

          <div css={styles.actions} className='mt-5 mx-auto'>
            <Button
              css={[commonClass.outlineButton, styles.cancelBtn]}
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button
              onClick={handleConfirm}
              css={[commonClass.appButton, styles.confirmBtn]}
              disabled={loading}
            >
              {!!loading && <CircularProgress size={18} />}
              CONFIRM
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  )
}

export default AddPointModal
