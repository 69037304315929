/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'

import createCache from '@emotion/cache'
import { BrowserRouter } from 'react-router-dom'

import { useEffect } from 'react'
import Header from 'components/header/Header'
import { globalTheme } from 'theme'
import Router from 'routes'
import GlobalAlert from 'components/global-alert/GlobalAlert'
import useEagerConnect from 'hooks/useEagerConnect'
import { useAppDispatch } from 'store'
import { fetchFirebaseConfig } from 'store/reducers/auth'
const cache = createCache({
  key: 'css',
  prepend: true,
})

const App = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchFirebaseConfig({}))
  }, [])
  useEagerConnect()

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={globalTheme}>
        <div>
          <BrowserRouter>
            <Header />
            <Router />
          </BrowserRouter>
          <GlobalAlert />
        </div>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default App
