/** @jsxImportSource @emotion/react */
import _get from 'lodash/get'
import { Button, Typography } from '@mui/material'
import { css } from '@emotion/react'
import _toLower from 'lodash/toLower'
import { baseColors, commonClass } from 'theme'
import {
  capitalizeFirstLetter,
  getClassObj,
  getColorStatus,
  getManaImage,
  getShoeAttribute,
  getStoneImage,
  getStoneTypeColor,
} from 'helpers/utils'
import { NFT_TYPE, NFT_STATUS } from 'types/common'
import ImagePlaceholder from 'components/image-placeholder/ImagePlaceholder'
import Images from 'images'
import { IMana, IShoe, IShoeBox, IStone } from 'types/nft'
import styles from './ProductItem.styles'
interface IProps {
  itemDetail: IShoe | IShoeBox | IStone | IMana
  onItemClick?: () => void
  onBuyClick?: () => void
  price?: number
  type?: string
  owner?: boolean
  isBuyItem?: boolean
  hideMetric?: boolean
  wrapStyle?: any
}
const ProductItem = ({
  itemDetail,
  onItemClick,
  onBuyClick,
  price,
  type,
  hideMetric,
  isBuyItem,
  owner,
  wrapStyle,
}: IProps) => {
  const qualityText =
    capitalizeFirstLetter((itemDetail as any)?.quality) || 'Common'
  const qualityColor = _get(baseColors, qualityText + 'Color')

  const renderShoeContent = (itemDetail: IShoe) => {
    const shoeImg = itemDetail?.image

    return (
      <div css={styles.shoeDetail}>
        <div css={styles.imgWrap}>
          <ImagePlaceholder
            placeholderImg={Images.ShoePlaceholder}
            placeholderStyle={styles.shoePlaceholder}
            style={styles.shoeImg}
            src={shoeImg}
          />
        </div>
        <Typography color={qualityColor} css={styles.shoeId}>
          #{itemDetail?.shoeId}
        </Typography>
        {!hideMetric && (
          <Typography css={styles.shoeInfo} color={baseColors.neutralColor1}>
            Mint {itemDetail?.mint} | Level {itemDetail?.level} |{' '}
            {itemDetail?.durable}%
          </Typography>
        )}
      </div>
    )
  }

  const renderShoeboxContent = (itemDetail: IShoeBox) => {
    const shoeBoxImg = (Images.shoebox as any)[
      _toLower(itemDetail?.quality) || 'common'
    ] as string

    return (
      <div css={styles.shoeDetail}>
        <div css={styles.imgWrap}>
          <img alt='stone' css={styles.shoeImg} src={shoeBoxImg} />
        </div>
        <Typography color={qualityColor} css={styles.shoeId}>
          #{itemDetail?.shoeBoxId}
        </Typography>
      </div>
    )
  }

  const renderStoneContent = (itemDetail: IStone) => {
    const stoneImg = getStoneImage(itemDetail.type, itemDetail.level)
    const typeText = capitalizeFirstLetter(itemDetail.type)
    const colorType = getStoneTypeColor(typeText)

    return (
      <div css={styles.shoeDetail}>
        <div css={styles.stoneImgWrap}>
          <img alt='stone' css={styles.shoeImg} src={stoneImg} />
        </div>
        <Typography color={colorType} css={styles.shoeId}>
          #{itemDetail?.stoneId}
        </Typography>
        <Typography css={styles.shoeInfo} color={baseColors.neutralColor1}>
          +{itemDetail.attribute} | +{itemDetail.awakeningEffect}% | Level{' '}
          {itemDetail.level}
        </Typography>
      </div>
    )
  }

  const renderManaContent = (itemDetail: IMana) => {
    const manaImg = getManaImage(itemDetail.quality, itemDetail.size)

    return (
      <div css={styles.shoeDetail}>
        <div css={styles.stoneImgWrap}>
          <img alt='stone' css={styles.shoeImg} src={manaImg} />
        </div>
        <Typography color={qualityColor} css={styles.shoeId}>
          #{itemDetail?.manaId}
        </Typography>
        <Typography css={styles.shoeInfo} color={baseColors.neutralColor1}>
          {capitalizeFirstLetter(itemDetail.size)} | +{itemDetail.point} point
        </Typography>
      </div>
    )
  }

  const renderShoeProperties = (itemDetail: IShoe) => {
    const { properties } = getShoeAttribute(itemDetail) || { properties: [] }

    if (properties?.length) {
      return (
        <div css={styles.bottom}>
          <div css={styles.metricWrapper}>
            {properties.map((p) => (
              <div key={p.label} css={styles.metricItem}>
                <div css={[styles.attrIcon, css(p.iconStyle)]} className='dfc'>
                  <span css={css({ color: p.iconStyle.color })}>
                    {p.label[0]}
                  </span>
                </div>
                <span css={styles.attrValue}>{p.total}</span>
              </div>
            ))}
          </div>
        </div>
      )
    }

    return null
  }

  const renderShoeTopLeft = (itemDetail: IShoe) => {
    const { label: classLabel, value: classValue } = getClassObj(
      itemDetail?.class,
    )

    const ClassIcon = Images.classIcon[classValue]

    return (
      <div
        css={[
          styles.class,
          css`
            color: ${qualityColor};
            svg path {
              fill: ${qualityColor};
            }
          `,
        ]}
      >
        <ClassIcon />
        {classLabel}
      </div>
    )
  }

  const renderStoneTopLeft = (itemDetail: IStone) => {
    const typeText = capitalizeFirstLetter(itemDetail.type)
    const colorType = getStoneTypeColor(typeText)

    return (
      <div
        css={[
          styles.class,
          css`
            color: ${colorType};
          `,
        ]}
      >
        {typeText}
      </div>
    )
  }

  const renderShoeBoxTopLeft = () => {
    return (
      <div
        css={[
          styles.class,
          css`
            color: ${qualityColor};
          `,
        ]}
      >
        {qualityText}
      </div>
    )
  }

  return (
    <div css={styles.wrapper} style={wrapStyle} onClick={onItemClick}>
      <div css={styles.top}>
        {type === NFT_TYPE.SHOE && renderShoeTopLeft(itemDetail as IShoe)}
        {type === NFT_TYPE.SHOEBOX && renderShoeBoxTopLeft()}
        {type === NFT_TYPE.STONE && renderStoneTopLeft(itemDetail as IStone)}
        {type === NFT_TYPE.MANA && renderShoeBoxTopLeft()}

        {!isBuyItem &&
          itemDetail.status &&
          itemDetail.status !== NFT_STATUS.CREATED &&
          itemDetail.status !== NFT_STATUS.ACTIVE && (
            <span
              css={styles.statusText}
              style={{ color: getColorStatus(itemDetail.status) }}
            >
              {capitalizeFirstLetter(itemDetail.status)}
            </span>
          )}
      </div>
      <div css={styles.content}>
        {type === NFT_TYPE.SHOE && renderShoeContent(itemDetail as IShoe)}
        {type === NFT_TYPE.SHOEBOX &&
          renderShoeboxContent(itemDetail as IShoeBox)}
        {type === NFT_TYPE.STONE && renderStoneContent(itemDetail as IStone)}
        {type === NFT_TYPE.MANA && renderManaContent(itemDetail as IMana)}
      </div>

      {!hideMetric && !!isBuyItem && (
        <div css={styles.bottom}>
          <Typography css={styles.price} color={qualityColor}>
            {price} FIT
          </Typography>

          {!owner && (
            <Button
              css={[commonClass.appButton, styles.buyBtn]}
              onClick={(e) => {
                e.stopPropagation()
                if (onBuyClick) {
                  onBuyClick()
                }
              }}
            >
              BUY
            </Button>
          )}
        </div>
      )}

      {type === NFT_TYPE.SHOE &&
        !hideMetric &&
        !isBuyItem &&
        renderShoeProperties(itemDetail as IShoe)}
    </div>
  )
}

export default ProductItem
