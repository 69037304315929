import { css } from '@emotion/react'
import { tabletWidth } from 'theme'

const styles = {
  stoneImgWrap: css`
    height: 32px;
    width: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  stoneImg: css`
    height: 28px;
    width: 28px;
  `,
  socket: css`
    height: 32px;
    width: 32px;
    position: absolute;
    svg {
      height: 32px;
      width: 32px;
    }
  `,
  socket1: css`
    top: 32px;
    left: 40px;
  `,
  socket2: css`
    top: 32px;
    right: 40px;
  `,
  socket3: css`
    bottom: 180px;
    left: 40px;

    @media only screen and (min-width: ${tabletWidth}px) {
      bottom: 94px;
    }
  `,
  socket4: css`
    bottom: 180px;
    right: 40px;

    @media only screen and (min-width: ${tabletWidth}px) {
      bottom: 94px;
    }
  `,
}

export default styles
