import { Request } from 'helpers/request'
import {
  IMarketListItem,
  IMarketListRequest,
  IMarketListResponse,
} from 'types/market'
// import { generateMockMarketShoe } from './mock'

export const getMarketListApi = (payload: IMarketListRequest) => {
  // if (payload.type === 'SHOE') {
  //   return Promise.resolve(generateMockMarketShoe(payload))
  // }

  return Request.call<IMarketListResponse>({
    url: '/market.list',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const getMarketItemApi = (payload: { marketItemId: number }) => {
  return Request.call<IMarketListItem>({
    url: '/market.item.detail',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const buyMarketItemApi = (payload: { marketItemId: number }) => {
  return Request.call<{
    error?: string
    message?: string
  }>({
    url: '/market.item.buy',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const sellItemApi = (payload: {
  itemId: number
  type: string
  price: number
}) => {
  return Request.call<{
    error?: string
    message?: string
  }>({
    url: '/market.item.sell',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const revokeItemApi = (payload: { itemId: number; type: string }) => {
  return Request.call<{
    error?: string
    message?: string
  }>({
    url: '/market.item.revoke',
    method: 'POST',
    data: {
      ...payload,
    },
  })
}
