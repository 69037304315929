/** @jsxImportSource @emotion/react */
import { Drawer, Avatar, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import _startsWith from 'lodash/startsWith'
import useAuth from 'hooks/useAuth'
import { commonClass } from 'theme'
import { useAppDispatch, useAppSelector } from 'store'
import { removeUserToken, fetchMeInfo } from 'store/reducers/auth'
import { ROUTE_PATHS } from 'routes'
import { fetchWalletBallance } from 'store/reducers/wallet'
import { toFixedNumber } from 'helpers/utils'
import Images from 'images'
import styles from './UserMenu.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const UserMenu = ({ isOpen, handleClose }: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const location = useLocation()

  const accessToken = useAppSelector((state) => state.auth.token)
  const walletBallance = useAppSelector((state) => state.wallet)
  const userInfo = useAppSelector((state) => state.auth.userInfo)
  const handleLogout = () => {
    logout()
    dispatch(removeUserToken())
  }

  const handleNavigate = (pathName: string) => {
    navigate(pathName)

    handleClose()
  }

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchMeInfo({}))
      dispatch(fetchWalletBallance({}))
    }
  }, [accessToken])

  const isMatchPath = (path: string) => {
    return _startsWith(location.pathname, path)
  }

  const menuNavs = [
    {
      icon: Images.MarketIcon,
      activeIcon: Images.MarketActiveIcon,
      label: 'Market Place',
      url: ROUTE_PATHS.MARKET,
    },
    {
      icon: Images.BagIcon,
      activeIcon: Images.BagActiveIcon,
      label: 'Bag',
      url: ROUTE_PATHS.BAG,
    },
    {
      icon: Images.BagIcon,
      activeIcon: Images.BagActiveIcon,
      label: 'Wallet',
      url: ROUTE_PATHS.WALLET_SPENDING,
    },
  ]

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={handleClose}>
      <div css={styles.menuWrapper}>
        <div className='df jcb'>
          {accessToken && (
            <div css={styles.userInfo}>
              <div css={styles.userFullName}>{userInfo?.fullName}</div>
              <div css={styles.userEmail}>{userInfo?.email}</div>
            </div>
          )}
          <div>
            <Avatar
              src='/assets/images/avatar.png'
              sx={{ height: 40, width: 40 }}
            />
          </div>
        </div>

        <div css={styles.walletInfo}>
          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.CaloTokenIcon} alt='calo token' />
              <span>Calo</span>
            </div>

            <span>{toFixedNumber(walletBallance.calo)}</span>
          </div>

          <div css={styles.walletInfoItem}>
            <div css={styles.tokenIcon}>
              <img src={Images.FitTokenIcon} alt='fit token' />
              <span>FIT</span>
            </div>

            <span>{toFixedNumber(walletBallance.fit)}</span>
          </div>
        </div>

        <div css={styles.navigateMenu}>
          {menuNavs.map((menuItem) => (
            <div
              key={menuItem.label}
              css={styles.menuItem}
              onClick={() => handleNavigate(menuItem.url)}
              className={isMatchPath(menuItem.url) ? 'active' : ''}
            >
              <img
                src={
                  isMatchPath(menuItem.url)
                    ? menuItem.activeIcon
                    : menuItem.icon
                }
                alt={menuItem.label}
              />
              {menuItem.label}

              <Images.ArrowRight />
            </div>
          ))}
        </div>

        <div css={styles.bottomMenuWrapper}>
          {accessToken && (
            <Button
              onClick={handleLogout}
              css={[commonClass.appButton, styles.signOutBtn]}
            >
              Sign Out
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default UserMenu
