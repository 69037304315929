/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import _toLower from 'lodash/toLower'
import _get from 'lodash/get'
import { useEffect, useRef, useState } from 'react'
import lottie from 'lottie-web'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import { capitalizeFirstLetter } from 'helpers/utils'
import { IShoeBox } from 'types/nft'
import animations from 'animations'
import styles from './MintResult.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  onOpen: () => void
  itemDetail: IShoeBox | null
}

const MintResult = ({ isOpen, handleClose, itemDetail, onOpen }: IProps) => {
  const [loading, setLoading] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const animationWrapperRef = useRef(null)

  useEffect(() => {
    if (!isOpen) {
      setLoading(false)
      setStartAnimation(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (itemDetail?.shoeBoxId) {
      setStartAnimation(true)
    }
  }, [itemDetail])

  const qualityText = capitalizeFirstLetter(itemDetail?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')
  const qualityBg = (Images.qualityBg as any)[
    _toLower(itemDetail?.quality) || 'common'
  ] as string

  const shoeboxImg = (Images.shoebox as any)[
    _toLower(itemDetail?.quality) || 'common'
  ] as string

  const renderAnimation = () => {
    return (
      <div css={styles.animationWrap}>
        <img className='box-img' src={shoeboxImg} alt='' />
        <img
          className='shoe-img shoe-left'
          src={itemDetail?.leftShoe?.image}
          alt=''
        />
        <img
          className='shoe-img shoe-right'
          src={itemDetail?.rightShoe?.image}
          alt=''
        />
        <div ref={animationWrapperRef} className='svg-wrapper'></div>
      </div>
    )
  }

  useEffect(() => {
    if (animationWrapperRef.current && startAnimation) {
      setTimeout(() => {
        lottie.loadAnimation({
          container: animationWrapperRef.current as unknown as HTMLElement, // the dom element that will contain the animation
          renderer: 'canvas',
          loop: true,
          autoplay: true,
          animationData: animations.mintShoe,
        })
        setTimeout(() => {
          setLoading(false)
        }, 500)
        setTimeout(() => {
          setStartAnimation(false)
        }, 6000)
      }, 3000)
    }
  }, [animationWrapperRef, startAnimation])

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        {startAnimation && renderAnimation()}
        {!startAnimation && (
          <Box css={styles.wrapper}>
            <>
              <div className='df jcb'>
                <div css={styles.modalTitle}>Shoebox detail</div>
                <div className='pointer' onClick={handleClose}>
                  <Images.CloseIcon />
                </div>
              </div>
              <div css={styles.bgWrapper}>
                <WrapperBgImg src={qualityBg}>
                  <div css={styles.bgWrapperContent}>
                    <img css={styles.shoeImg} src={shoeboxImg} alt='' />

                    <div
                      className='mb-2'
                      css={styles.shoeId}
                      style={{ color: qualityColor }}
                    >
                      #{itemDetail?.shoeBoxId}
                    </div>
                  </div>
                </WrapperBgImg>
              </div>
              <p css={styles.mess}>Congrats! You got new Shoebox</p>

              <div css={styles.actions} className='mt-5 mx-auto'>
                <Button
                  onClick={() => handleClose()}
                  css={[commonClass.outlineButton, styles.action]}
                >
                  COLLECT
                </Button>
                <Button
                  onClick={() => onOpen()}
                  css={[commonClass.appButton, styles.action]}
                  disabled={loading}
                >
                  {!!loading && <CircularProgress size={18} />}
                  OPEN
                </Button>
              </div>
            </>
          </Box>
        )}
      </>
    </Modal>
  )
}

export default MintResult
