import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  getCaloContract,
  getFitContract,
  getShoeContract,
  getShoeboxContract,
} from 'utils/contractHelpers'
import ERC20_ABI from '../config/abi/erc20.json'
import { getContract } from '../utils'

function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(
        address,
        ABI,
        withSignerIfPossible ? library : undefined,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error('Failed to get contract', error)

      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export const useCaloContract = () => {
  const { library } = useActiveWeb3React()

  return useMemo(() => getCaloContract(library?.getSigner()), [library])
}

export const useFitContract = () => {
  const { library } = useActiveWeb3React()

  return useMemo(() => getFitContract(library?.getSigner()), [library])
}

export const useShoeContract = () => {
  const { library } = useActiveWeb3React()

  return useMemo(() => getShoeContract(library?.getSigner()), [library])
}

export const useShoeboxContract = () => {
  const { library } = useActiveWeb3React()

  return useMemo(() => getShoeboxContract(library?.getSigner()), [library])
}
