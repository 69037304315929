/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from 'iconsax-react'
import _get from 'lodash/get'
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Typography,
} from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { baseColors, commonClass } from 'theme'
import { useAppDispatch } from 'store'
import { getShoeDetail } from 'api/wallet'
import { IShoe } from 'types/nft'
import { capitalizeFirstLetter } from 'helpers/utils'
import { getShoeContract } from 'utils/contractHelpers'
import { showToast } from 'store/reducers/common'
import { ROUTE_PATHS } from 'routes'
import styles from './NFTTransferExternal'
import NFTTransferConfirmModal from './NFTTransferConfirmModal'

const ShoeTransferExternal = () => {
  const navigate = useNavigate()
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const { shoeId } = useParams()

  const [data, setData] = useState<IShoe>()
  const [address, setAddress] = useState<string>('')
  const [owner, setOwner] = useState('')

  const [loading, setLoading] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [isOpenConfirm, setIsOpenConfirm] = useState(false)

  const [transferData, setTransferData] = useState<any>()

  const qualityText = capitalizeFirstLetter(data?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')

  const isAddress = ethers.utils.isAddress(address)

  const getShoeInfo = async () => {
    const res = await getShoeDetail(shoeId)

    setData(res)
  }

  const getNFTOwner = async () => {
    const contract = getShoeContract()
    const tx = await contract.ownerOf(shoeId)

    setOwner(tx)
  }

  const getFeeTransaction = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()

    try {
      const contract = getShoeContract()

      const txGasPrice = await signer.getGasPrice()
      const txGasLimit = await contract.estimateGas.transferFrom(
        account,
        address?.trim(),
        shoeId,
      )
      const transactionFee = +txGasPrice * +txGasLimit

      return ethers.utils.formatEther(transactionFee)
    } catch (error) {
      throw error
    }
  }

  const handleConfirmFee = async () => {
    setLoadingConfirm(true)
    try {
      const transactionFee = await getFeeTransaction()

      setTransferData({
        recipient: address,
        fee: transactionFee,
      })
      setIsOpenConfirm(true)
      setLoadingConfirm(false)
    } catch (error: any) {
      setLoadingConfirm(false)
      dispatch(
        showToast({
          title: error?.message || 'Error, please try again',
        }),
      )
    }
  }

  const handleTransferNFT = async () => {
    setLoading(true)
    const { recipient } = transferData
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = getShoeContract(signer)

    try {
      const sender = account
      const tx = await contract.transferFrom(sender, recipient, shoeId)

      await tx.wait()
      setIsOpenConfirm(false)
      setLoading(false)
      dispatch(
        showToast({
          title:
            'Transaction has submitted. This progress will take (5-10 minutes) to finish.',
        }),
      )
      navigate(ROUTE_PATHS.WALLET_ONCHAIN)
    } catch (error: any) {
      setLoading(false)
      dispatch(
        showToast({
          title: error?.message || 'Error, please try again',
        }),
      )
    }
  }

  useEffect(() => {
    getShoeInfo()
    getNFTOwner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoeId])

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      {owner === '' && null}
      {account === owner && (
        <div css={styles.contentWrapper}>
          <div css={styles.pageHeader}>
            <Link to={ROUTE_PATHS.WALLET_SHOE} css={styles.iconBack}>
              <ArrowLeft size='24' color='#D0E0F7' className='spending' />
            </Link>
            <h3>Send to</h3>
          </div>
          <div css={styles.nftImgWrapper}>
            <img css={styles.nftImg} src={data?.image} alt='icon' />
            <Typography color={qualityColor} css={styles.id}>
              #{shoeId}
            </Typography>
          </div>
          <div css={styles.formWrapper}>
            <FormControl fullWidth>
              <div css={styles.formLabel}>To address</div>
              <OutlinedInput
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                css={styles.inputWrapper}
              />
            </FormControl>
          </div>
          <Button
            onClick={handleConfirmFee}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={loadingConfirm || !isAddress}
          >
            <div className='dfc'>
              {loadingConfirm && <CircularProgress size={18} />}
              <span>CONFIRM</span>
            </div>
          </Button>
        </div>
      )}
      {account !== owner && <div>You are not owned this NFT</div>}
      {transferData && (
        <NFTTransferConfirmModal
          isOpen={isOpenConfirm}
          onClose={() => setIsOpenConfirm(false)}
          isLoading={loading}
          transferData={transferData}
          onConfirm={handleTransferNFT}
        />
      )}
    </div>
  )
}

export default ShoeTransferExternal
