import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    border-radius: 8px;
  `,
  top: css`
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border-bottom: 1px solid #2b3755;
  `,
  class: css`
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
  `,
  status: css``,
  statusText: css`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
  `,
  bottom: css`
    border-top: 1px solid #2b3755;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 36px;
  `,
  price: css`
    font-weight: 600;
    font-size: 14px;
    color: ${baseColors.neutralColor1};
    letter-spacing: 0.4px;
  `,
  buyBtn: css`
    height: 26px;
    width: 53px;
    font-size: 14px;
    border: 16px;
    font-weight: 600;
  `,

  content: css`
    height: 149px;
  `,
  shoeDetail: css`
    height: 149px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  `,
  imgWrap: css`
    width: 100px;
    height: 100px;
    margin-top: -20px;
  `,
  shoeImg: css`
    width: 100%;
    height: 100%;
  `,
  stoneImgWrap: css`
    width: 80px;
    height: 80px;
    margin-top: -20px;
    margin-bottom: 5px;
  `,
  shoePlaceholder: css`
    width: 100px;
    height: 100px;
  `,
  shoeId: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
  `,
  shoeInfo: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  `,
  metricWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 190px;
    margin: 0 auto;
    width: 100%;
  `,
  metricItem: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  attrValue: css`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${baseColors.neutralColor1};
  `,
  attrIcon: css`
    height: 12px;
    width: 12px;
    border-radius: 16px;
    margin-right: 2px;
    span {
      font-size: 8px;
    }
  `,
}

export default styles
