/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import _take from 'lodash/take'
import { baseColors } from 'theme'
import styles from './ProgressBar.styles'

interface IProps {
  split?: any
  maxValue?: any
  value?: any
  value2?: any
  value3?: any
  completeColor?: any
  height?: any
}
const ProgressBar = ({
  split,
  maxValue,
  value,
  value2,
  value3,
  completeColor,
  height = 8,
}: IProps) => {
  const completedBar1Style = [
    styles.completedBar,
    css({
      height: height,
      ...(completeColor && { backgroundColor: completeColor }),
    }),
  ]
  const completedBar2Style = [
    styles.completedBar,
    css({
      height: height,
      backgroundColor: baseColors.progressBarColor2,
    }),
  ]

  const completedBar3Style = [
    styles.completedBar,
    css({
      height: height,
      backgroundColor: baseColors.progressBarColor3,
    }),
  ]

  const renderSplitBar = () => {
    const bars = []
    const barWidth = 96 / maxValue + '%'

    for (let i = 1; i <= maxValue; i++) {
      const firstBar = i === 1
      const lastBar = i === maxValue
      const radiusStyles = {
        ...(firstBar && {
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        }),
        ...(lastBar && {
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
        }),
      }

      bars.push(
        <div
          key={i}
          css={[
            styles.bar,
            css({ width: barWidth, height: height, ...radiusStyles }),
          ]}
        >
          <div
            css={[
              completedBar1Style,
              css({ width: i <= value ? '100%' : 0, ...radiusStyles }),
            ]}
          />
        </div>,
      )
    }

    return <div css={styles.splitBarWrapper}>{bars}</div>
  }

  const renderProgressBar = () => {
    const completeBars: { style: any[] }[] = []

    ;[
      {
        value: value,
        style: completedBar1Style,
      },
      {
        value: value2,
        style: completedBar2Style,
      },
      {
        value: value3,
        style: completedBar3Style,
      },
    ].forEach((v, i) => {
      if (v.value && Number(v.value)) {
        completeBars.push({
          style: [
            v.style,
            css({
              width: Math.round((v.value / maxValue) * 100) + '%',
            }),
          ],
        })
      }
    })

    if (!completeBars.length) {
      return (
        <div css={[styles.bar, css({ borderRadius: 16, height: height })]} />
      )
    }

    _take(completeBars)[0].style.push(
      css({
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
      }),
    )

    // _takeRight(completeBars)[0].style.push(css({
    //   borderTopRightRadius: 16,
    //   borderBottomRightRadius: 16,
    // }))

    return (
      <div css={[styles.bar, css({ borderRadius: 16, height: height })]}>
        {completeBars.map((c, idx) => (
          <div key={idx} css={c.style} />
        ))}
      </div>
    )
  }

  return split ? renderSplitBar() : renderProgressBar()
}

export default ProgressBar
