import { css } from '@emotion/react'
import { baseColors } from 'theme'
const styles = {
  bar: css({
    backgroundColor: baseColors.progressBarBackground,
    height: 8,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
  }),
  completedBar: css({
    height: 8,
    backgroundColor: baseColors.progressBarColor1,
  }),
  splitBarWrapper: css({
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
  }),
}

export default styles
