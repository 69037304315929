import { ethers } from 'ethers'
import shoeAbi from 'config/abi/shoe.json'
import shoeboxAbi from 'config/abi/shoebox.json'
import bep20Abi from 'config/abi/erc20.json'
import pancakeRouterAbi from 'config/abi/pancakeRouter.json'
import depositWithdrawNFTAbi from 'config/abi/depositWithdrawNFT.json'
import depositWithdrawTokenAbi from 'config/abi/depositWithdrawToken.json'
import { simpleRpcProvider } from 'utils/providers'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider

  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}

export const getCaloContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_CALO_CONTRACT_ADDRESS || ''

  return getContract(bep20Abi, address, signer)
}

export const getFitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_FIT_CONTRACT_ADDRESS || ''

  return getContract(bep20Abi, address, signer)
}

export const getShoeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_SHOE_CONTRACT_ADDRESS || ''

  return getContract(shoeAbi, address, signer)
}

export const getShoeboxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_SHOEBOX_CONTRACT_ADDRESS || ''

  return getContract(shoeboxAbi, address, signer)
}

export const getDepositWithdrawTokenContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_DEPOSIT_WITHDRAW_TOKEN_CONTRACT_ADDRESS || ''

  return getContract(depositWithdrawTokenAbi, address, signer)
}

export const getDepositWithdrawShoeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_DEPOSIT_WITHDRAW_SHOE_CONTRACT_ADDRESS || ''

  return getContract(depositWithdrawNFTAbi, address, signer)
}

export const getDepositWithdrawShoeboxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_DEPOSIT_WITHDRAW_SHOEBOX_CONTRACT_ADDRESS || ''

  return getContract(depositWithdrawNFTAbi, address, signer)
}

export const getPancakeRouterContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  const address = process.env.REACT_APP_PANCAKE_ROUTER_CONTRACT_ADDRESS || ''

  return getContract(pancakeRouterAbi, address, signer)
}
