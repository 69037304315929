import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _map from 'lodash/map'
import {
  getShoeListApi,
  getShoeBoxListApi,
  openShoeBoxApi,
  mintShoeApi,
  costMintShoeApi,
  boostShoeTimeApi,
  upgradeShoeApi,
  getShoeDetailApi,
  getShoeBoxDetailApi,
  addShoePointApi,
  getRepairShoeCostApi,
  repairShoeApi,
  getManaListApi,
  getStoneListApi,
} from 'api/bag'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { IMana, IShoe, IShoeBox, IStone } from 'types/nft'
import {
  convertStructureShoe,
  convertStuctureMana,
  convertStuctureStone,
} from 'store/middlewares/convert-structure/nft'
export const TYPE = 'BAG'

export const fetchShoeDetail = createCallApiAsyncAction(
  TYPE + '/fetchShoeDetail',
  getShoeDetailApi,
  convertStructureShoe,
)

export const fetchShoeBoxDetail = createCallApiAsyncAction(
  TYPE + '/fetchShoeBoxDetail',
  getShoeBoxDetailApi,
)

export const fetchShoeBagList = createCallApiAsyncAction(
  TYPE + '/fetchShoeBagList',
  getShoeListApi,
  (old) => {
    return {
      data: _map(old.data, (v) => convertStructureShoe(v)),
    }
  },
)

export const fetchManaBagList = createCallApiAsyncAction(
  TYPE + '/fetchManaBagList',
  getManaListApi,
  (old) => ({ data: _map(old.data, (v) => convertStuctureMana(v)) }),
)

export const fetchStoneBagList = createCallApiAsyncAction(
  TYPE + '/fetchStoneBagList',
  getStoneListApi,
  (old) => ({ data: _map(old.data, (v) => convertStuctureStone(v)) }),
)

export const fetchShoeBoxBagList = createCallApiAsyncAction(
  TYPE + '/fetchShoeBoxBagList',
  getShoeBoxListApi,
)

export const fetchBagShoeItem = createCallApiAsyncAction(
  TYPE + '/fetchBagShoeItem',
  getShoeDetailApi,
  convertStructureShoe,
)

export const fetchOpenShoebox = createCallApiAsyncAction(
  TYPE + '/fetchOpenShoebox',
  openShoeBoxApi,
)
export const fetchMintShoe = createCallApiAsyncAction(
  TYPE + '/fetchMintShoe',
  mintShoeApi,
)

export const fetchCostMint = createCallApiAsyncAction(
  TYPE + '/fetchCostMint',
  costMintShoeApi,
)

export const upgradeShoe = createCallApiAsyncAction(
  TYPE + '/upgradeShoe',
  upgradeShoeApi,
)

export const boostShoeTime = createCallApiAsyncAction(
  TYPE + '/boostShoeTime',
  boostShoeTimeApi,
)

export const addShoePoint = createCallApiAsyncAction(
  TYPE + '/addShoePoint',
  addShoePointApi,
)

export const getRepairShoeCost = createCallApiAsyncAction(
  TYPE + '/getRepairShoeCost',
  getRepairShoeCostApi,
)

export const repairShoe = createCallApiAsyncAction(
  TYPE + '/repairShoe',
  repairShoeApi,
)

interface BagListState {
  shoeData: IShoe[]
  shoeBoxData: IShoeBox[]
  stoneData: IStone[]
  manaData: IMana[]
  shoeDetail: IShoe | null
}

const initialState: BagListState = {
  shoeData: [],
  shoeBoxData: [],
  stoneData: [],
  manaData: [],
  shoeDetail: null,
}

const bagListSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setBagShoeDetail(state, action: PayloadAction<IShoe>) {
      state.shoeDetail = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchManaBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.manaData = response?.data || []
      } else {
        state.manaData = []
      }
    })

    builder.addCase(fetchStoneBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.stoneData = response?.data || []
      } else {
        state.stoneData = []
      }
    })

    builder.addCase(fetchShoeBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeData = response?.data || []
      } else {
        state.shoeData = []
      }
    })

    builder.addCase(fetchShoeBoxBagList.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeBoxData = response?.data || []
      } else {
        state.shoeData = []
      }
    })

    builder.addCase(fetchBagShoeItem.fulfilled, (state, action) => {
      const { response } = action.payload

      if (response.apiStatus) {
        state.shoeDetail = response
      }
    })
  },
})

export const { setBagShoeDetail } = bagListSlice.actions
export default bagListSlice.reducer
