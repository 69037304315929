/** @jsxImportSource @emotion/react */
import { Box, Button } from '@mui/material'
import Images from 'images'
import { commonClass } from 'theme'
import { NFT_TYPE } from 'types/common'
import { WITHDRAW_STEPS, IWithdrawFee } from 'types/wallet'
import styles from './ConfirmModal.styles'

interface IProps {
  fee: IWithdrawFee
  token: any
  amount: string | undefined
  isLoading: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  setStep: (value: string) => void
}

const ConfirmInformation = ({
  fee,
  token,
  amount,
  isLoading,
  isOpen,
  onClose,
  onConfirm,
  setStep,
}: IProps) => {
  const renderTotalToken = () => {
    if (token?.type === NFT_TYPE.SHOE) {
      return `${token?.class} ${token?.quality} #${token?.shoeId}`
    } else if (token?.type === NFT_TYPE.SHOEBOX) {
      return `${token?.type} #${token?.shoeBoxId}`
    }

    return `${amount} ${token?.type}`
  }

  return (
    <Box css={styles.modalWrapper}>
      <div className='df jcb'>
        <div css={styles.modalTitle}>Confirm Transfer</div>
        <div className='pointer' onClick={onClose}>
          <Images.CloseIcon />
        </div>
      </div>

      <div css={styles.boxDirection}>
        <div css={styles.directionItem}>
          <div css={styles.directionFrom}>From</div>
          <div css={styles.directionTo}>Wallet</div>
        </div>
        <div css={styles.directionItem}>
          <div css={styles.directionFrom}>To</div>
          <div css={styles.directionTo}>Spending</div>
        </div>
      </div>
      <div css={styles.transferToken}>
        <div css={styles.transferItem}>
          <div css={styles.transferItemLabel}>Fee</div>
          <div css={styles.transferItemValue}>
            {Number(fee?.fitAmount) > 0 ? `${Number(fee?.fitAmount)} FIT` : ''}
            {Number(fee?.caloAmount) > 0 && Number(fee?.fitAmount) > 0
              ? '+'
              : ''}
            {Number(fee?.caloAmount) > 0
              ? `${Number(fee?.caloAmount)} CALO`
              : ''}
          </div>
        </div>

        <div css={styles.transferItem}>
          <div css={styles.transferItemLabel}>You will transfer</div>
          <div css={styles.transferItemValue}>{renderTotalToken()}</div>
        </div>
      </div>
      <div css={styles.actions} className='mt-5 mx-auto'>
        <Button
          css={[commonClass.outlineButton, styles.cancelBtn]}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => setStep(WITHDRAW_STEPS.OTP)}
          css={[commonClass.appButton, styles.confirmBtn]}
          disabled={isLoading}
        >
          CONFIRM
        </Button>
      </div>
    </Box>
  )
}

export default ConfirmInformation
