import { css } from '@emotion/react'

import { baseColors, tabletWidth } from 'theme'
const styles = {
  wrapper: css`
    display: flex;
  `,
  filterMobileWrapper: css`
    background-color: ${baseColors.cardBg};
    width: 320px;
    height: 100vh;

    @media only screen and (min-width: ${tabletWidth}px) {
      display: none;
    }
  `,
  filterWrapper: css`
    display: none;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: block;
      background-color: ${baseColors.cardBg};
      width: 320px;
      min-width: 320px;
    }
  `,
  contentWrapper: css`
    padding: 16px;
    flex: 1;
  `,
  topWrapper: css`
    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
      justify-content: center;
      position: relative;
    }
  `,
  listActionWrap: css`
    display: flex;
    margin-bottom: 1rem;
    @media only screen and (min-width: ${tabletWidth}px) {
      position: absolute;
      right: 0;
      margin-bottom: 0;
      top: 15px;
    }
  `,
  filterButton: css`
    @media only screen and (min-width: ${tabletWidth}px) {
      display: none;
    }
  `,
  scrollList: css`
    height: calc(100vh - 150px);
    overflow: auto;
    position: relative;

    @media only screen and (max-width: ${tabletWidth}px) {
      height: calc(100vh - 223px);
    }
  `,
  empty: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
  `,
  loading: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0005;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

export default styles
