/** @jsxImportSource @emotion/react */
import _get from 'lodash/get'
import { css } from '@emotion/react'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { capitalizeFirstLetter, getClassObj } from 'helpers/utils'
import { baseColors } from 'theme'
import ImagePlaceholder from 'components/image-placeholder/ImagePlaceholder'
import Images from 'images'
import ModalShoeAction from './ModalShoeAction'
import styles from './ShoeCard.styles'

interface IAttribute {
  attribute: string
  value: string
}

interface IShoe {
  shoe_id: number
  image: string
  name: string
  attributes: Array<IAttribute>
}

interface IProps {
  shoeId: number
  quality: string
  image: string
  itemDetail: IShoe
}

const ShoeCard = ({ shoeId, quality, image, itemDetail }: IProps) => {
  const qualityText = capitalizeFirstLetter(quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')

  const [open, setOpen] = useState(false)

  const getAttributeValue = (key: string, attributes: Array<IAttribute>) => {
    const attr = attributes.find((a) => a.attribute === key)

    return attr?.value as string
  }

  const renderShoeTopLeft = (itemDetail: IShoe) => {
    const shoeClass = getAttributeValue('class', itemDetail?.attributes)

    const { value: classValue } = getClassObj(shoeClass)

    const ClassIcon = Images.classIcon[classValue]

    return (
      <div
        css={[
          styles.class,
          css`
            color: ${qualityColor};
            svg path {
              fill: ${qualityColor};
            }
          `,
        ]}
      >
        <ClassIcon />
        {getAttributeValue('class', itemDetail?.attributes)}
      </div>
    )
  }

  const renderShoeContent = (itemDetail: IShoe) => {
    const shoeImg = itemDetail?.image

    return (
      <div css={styles.shoeDetail}>
        <div css={styles.imgWrap}>
          <ImagePlaceholder
            placeholderImg={Images.ShoePlaceholder}
            placeholderStyle={styles.shoePlaceholder}
            style={styles.shoeImg}
            src={shoeImg}
          />
        </div>
        <Typography color={qualityColor} css={styles.shoeId}>
          #{itemDetail?.shoe_id}
        </Typography>
        <Typography css={styles.shoeInfo} color={baseColors.neutralColor1}>
          Mint {getAttributeValue('mint', itemDetail?.attributes)} | Level{' '}
          {getAttributeValue('level', itemDetail?.attributes)} |{' '}
          {getAttributeValue('durable', itemDetail?.attributes)}%
        </Typography>
      </div>
    )
  }

  const renderShoeProperties = (itemDetail: IShoe) => {
    const properties = [
      {
        label: 'Performance',
        key: 'performance',
        iconStyle: {
          color: baseColors.neutralColor1,
          backgroundColor: baseColors.performanceColor,
        },
        value: getAttributeValue('performance', itemDetail?.attributes),
      },
      {
        label: 'Luck',
        key: 'luck',
        iconStyle: {
          color: baseColors.black,
          backgroundColor: baseColors.luckColor,
        },
        value: getAttributeValue('luck', itemDetail?.attributes),
      },
      {
        label: 'Speed',
        key: 'speed',
        iconStyle: {
          color: baseColors.neutralColor1,
          backgroundColor: baseColors.speedColor,
        },
        value: getAttributeValue('speed', itemDetail?.attributes),
      },
      {
        label: 'Recovery',
        key: 'recovery',
        iconStyle: {
          color: baseColors.black,
          backgroundColor: baseColors.recoveryColor,
        },
        value: getAttributeValue('recovery', itemDetail?.attributes),
      },
    ]

    return (
      <div css={styles.bottom}>
        <div css={styles.metricWrapper}>
          {properties.map((p) => (
            <div key={p.label} css={styles.metricItem}>
              <div css={[styles.attrIcon, css(p.iconStyle)]} className='dfc'>
                <span css={css({ color: p.iconStyle.color })}>
                  {p.label[0]}
                </span>
              </div>
              <span css={styles.attrValue}>{p.value}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <>
      <div css={styles.boxWrapper} onClick={() => setOpen(true)}>
        <div
          css={[
            styles.boxHeader,
            css`
              color: ${qualityColor};
              svg path {
                fill: ${qualityColor};
              }
            `,
          ]}
        >
          {renderShoeTopLeft(itemDetail as IShoe)}
        </div>
        <div css={styles.boxBody}>{renderShoeContent(itemDetail as IShoe)}</div>
        {renderShoeProperties(itemDetail as IShoe)}
      </div>
      <ModalShoeAction
        isOpen={open}
        handleClose={() => setOpen(false)}
        image={image}
        quality={quality}
        shoeId={shoeId}
      />
    </>
  )
}

export default ShoeCard
