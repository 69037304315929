import _get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'
import { baseColors, tabletWidth } from 'theme'
import { NFT_STATUS } from 'types/common'
import { IShoe } from 'types/nft'
import Images from 'images'

export const isMobile = () => window.innerWidth <= tabletWidth

export const getQualityName = (quality: string) => {
  return {
    COMMON: 'Common',
    UNCOMMON: 'Uncommon',
    RARE: 'Rare',
    EPIC: 'Epic',
    LEGENDARY: 'Legendary',
  }[quality || 'COMMON']
}

export const getClassObj = (className: string) => {
  const mapObj = {
    WALKER: {
      label: 'Walker',
      value: 1,
    },
    JOGGER: {
      label: 'Jogger',
      value: 2,
    },
    RUNNER: {
      label: 'Runner',
      value: 3,
    },
    TRAINER: {
      label: 'Trainer',
      value: 4,
    },
  }

  return _get(mapObj, className, mapObj.WALKER) as {
    label: string
    value: 1 | 2 | 3 | 4
  }
}

export const capitalizeFirstLetter = (string = '') => {
  if (!string) {
    return ''
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const getShoeAttribute = (shoeRawData: IShoe) => {
  const properties = [
    {
      label: 'Performance',
      key: 'performance',
      value: _get(shoeRawData, 'attributes.base.performance') as number,
      value2: _get(shoeRawData, 'attributes.upgrade.performance') as number,
      value3: _get(shoeRawData, 'attributes.socket.performance') as number,
      iconStyle: {
        color: baseColors.neutralColor1,
        backgroundColor: baseColors.performanceColor,
      },
      total: 0,
    },
    {
      label: 'Luck',
      key: 'luck',
      value: _get(shoeRawData, 'attributes.base.luck') as number,
      value2: _get(shoeRawData, 'attributes.upgrade.luck') as number,
      value3: _get(shoeRawData, 'attributes.socket.luck') as number,
      iconStyle: {
        color: baseColors.black,
        backgroundColor: baseColors.luckColor,
      },
      total: 0,
    },
    {
      label: 'Speed',
      key: 'speed',
      value: _get(shoeRawData, 'attributes.base.speed') as number,
      value2: _get(shoeRawData, 'attributes.upgrade.speed') as number,
      value3: _get(shoeRawData, 'attributes.socket.speed') as number,
      iconStyle: {
        color: baseColors.neutralColor1,
        backgroundColor: baseColors.speedColor,
      },
      total: 0,
    },
    {
      label: 'Recovery',
      key: 'recovery',
      value: _get(shoeRawData, 'attributes.base.recovery') as number,
      value2: _get(shoeRawData, 'attributes.upgrade.recovery') as number,
      value3: _get(shoeRawData, 'attributes.socket.recovery') as number,
      iconStyle: {
        color: baseColors.black,
        backgroundColor: baseColors.recoveryColor,
      },
      total: 0,
    },
  ]

  const maxAttr = properties.reduce((prev, curr) => {
    curr.total = toFixedNumber(
      (Number(curr.value) || 0) +
        (Number(curr.value2) || 0) +
        (Number(curr.value3) || 0),
    )

    return curr.total > prev ? curr.total : prev
  }, 1)

  return { properties, maxAttr }
}

export const toFixedNumber = (num: any, fix = 1) => {
  try {
    return Number(num).toFixed(fix)
  } catch (e) {
    return num
  }
}

export const formatInputNumber = (e: string) => {
  let num = e.replace(/[^0-9,.]/g, '')
  let rs = 0

  num = num.replace(',', '.')
  num = num.replace('..', '.')
  if (num.split('.').length >= 3) {
    num = num.replace('.', '')
  }
  if (num[num.length - 1] !== '.' && num !== '' && parseFloat(num) > 0) {
    rs = 1 * (num as any)
  }

  return rs
}

export const getColorStatus = (status: string) => {
  return {
    [NFT_STATUS.ACTIVE]: baseColors.neutralColor1,
    [NFT_STATUS.COOLDOWN]: baseColors.colddownColor,
    [NFT_STATUS.LEVELING]: baseColors.levelingColor,
    [NFT_STATUS.LOCKED]: baseColors.lockedColor,
    [NFT_STATUS.SELLING]: baseColors.sellingColor,
  }[status || NFT_STATUS.ACTIVE]
}

export const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const getDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId')

  if (!deviceId) {
    deviceId = uuidv4() as string
    localStorage.setItem('deviceId', deviceId)
  }

  return deviceId
}

export const getStoneTypeColor = (type: string) => {
  return {
    Performance: baseColors.performanceColor,
    Recovery: baseColors.recoveryColor,
    Speed: baseColors.speedColor,
    Luck: baseColors.luckColor,
  }[type || 'Performance']
}

export const getStoneImage = (stoneType = 'LUCK', level: number) => {
  const out = stoneType.toLowerCase() + level

  return (Images.stones as any)[out]
}

export const getManaImage = (quality: string, size: string) => {
  let out = 'mana'

  out = out + capitalizeFirstLetter(quality) + capitalizeFirstLetter(size)

  return (Images.manas as any)[out]
}
