import { css } from '@emotion/react'
import { baseColors } from 'theme'
const styles = {
  wrapper: css`
    display: flex;
    justify-content: space-between;
    height: 24px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    max-width: 400px;
  `,
  level: css`
    width: 33%;
    background-color: #2a2142;
    height: 100%;
    border-radius: 12px 0 0 12px;
  `,

  class: css`
    width: 33%;
    background-color: #2a2142;
    height: 100%;
  `,

  durableWrapper: css`
    position: relative;
    display: flex;
    overflow: hidden;
    width: 33%;
    background: #2a2142;
    height: 24px;
    justify-items: center;
    border-radius: 0 12px 12px 0;
  `,
  durableFront: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #2a2142;
    color: ${baseColors.neutralColor1};
    border-radius: 0 12px 12px 0;
  `,
  durableBack: css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: ${baseColors.neutralColor12};
    border-radius: 0 12px 12px 0;
  `,
}

export default styles
