import { ReactComponent as RefreshIcon } from './svg/refresh.svg'
import { ReactComponent as ArrowDown } from './svg/arrow-down.svg'
import { ReactComponent as ArrowRight } from './svg/arrow-right.svg'
import { ReactComponent as FilterIcon } from './svg/filter.svg'
import { ReactComponent as CloseIcon } from './svg/close.svg'
import { ReactComponent as BackIcon } from './svg/back.svg'

import { ReactComponent as Class1 } from './svg/class/class-1.svg'
import { ReactComponent as Class2 } from './svg/class/class-2.svg'
import { ReactComponent as Class3 } from './svg/class/class-3.svg'
import { ReactComponent as Class4 } from './svg/class/class-4.svg'

import SocketLockAvailableLuck from './svg/sockets/sockets-lock-l.svg'
import SocketLockAvailablePerformance from './svg/sockets/sockets-lock-p.svg'
import SocketLockAvailableRecovery from './svg/sockets/sockets-lock-r.svg'
import SocketLockAvailableSpeed from './svg/sockets/sockets-lock-s.svg'

import { ReactComponent as SocketUnLockCommon } from './svg/sockets/common.svg'
import { ReactComponent as SocketUnLockUncommon } from './svg/sockets/uncommon.svg'
import { ReactComponent as SocketUnLockRare } from './svg/sockets/rare.svg'
import { ReactComponent as SocketUnLockEpic } from './svg/sockets/epic.svg'
import { ReactComponent as SocketUnLockLegendary } from './svg/sockets/legendary.svg'

import { ReactComponent as MenuLevelup } from './svg/shoe-detail-actions/levelup.svg'
import { ReactComponent as MenuMint } from './svg/shoe-detail-actions/mint.svg'
import { ReactComponent as MenuRepair } from './svg/shoe-detail-actions/repair.svg'
import { ReactComponent as MenuSell } from './svg/shoe-detail-actions/sell.svg'
import { ReactComponent as MenuTransfer } from './svg/shoe-detail-actions/transfer.svg'

import AddShoe from './svg/add-shoe.svg'
import EmptyShoe from './svg/empty-shoe.svg'

import ManaEmpty from './svg/empty/mana.svg'
import StoneEmpty from './svg/empty/stone.svg'
import ShoeEmpty from './svg/empty/shoe.svg'
import ShoeboxEmpty from './svg/empty/shoebox.svg'

const Images = {
  SocketsLock: {
    Luck: SocketLockAvailableLuck,
    Performance: SocketLockAvailablePerformance,
    Recovery: SocketLockAvailableRecovery,
    Speed: SocketLockAvailableSpeed,
  },
  SocketUnLock: {
    Common: SocketUnLockCommon,
    Uncommon: SocketUnLockUncommon,
    Rare: SocketUnLockRare,
    Epic: SocketUnLockEpic,
    kLegendary: SocketUnLockLegendary,
  },

  Empty: {
    Mana: ManaEmpty,
    Stone: StoneEmpty,
    Shoe: ShoeEmpty,
    Shoebox: ShoeboxEmpty,
  },
  // svg
  RefreshIcon,
  ArrowDown,
  ArrowRight,
  FilterIcon,
  CloseIcon,
  BackIcon,
  AddShoe,
  EmptyShoe,

  shoeMenuIcons: {
    MenuLevelup,
    MenuMint,
    MenuRepair,
    MenuSell,
    MenuTransfer,
  },
  classIcon: {
    1: Class1,
    2: Class2,
    3: Class3,
    4: Class4,
  },

  // png, jpg...
  MinusWhite: require('./img/minus-white.png'),
  PlusWhite: require('./img/plus-white.png'),
  ShoePlaceholder: require('./img/shoe-placeholder.png'),
  FitTokenIcon: require('./img/f-token.png'),
  CaloTokenIcon: require('./img/calo-token.png'),
  BnbTokenIcon: require('./img/bnb-token.png'),
  ShoeIcon: require('./img/shoe-icon.png'),
  ShoeboxIcon: require('./img/shoebox-icon.png'),
  qualityBg: {
    common: require('./img/quality-bg/bgCommon.png'),
    epic: require('./img/quality-bg/bgEpic.png'),
    legendary: require('./img/quality-bg/bgLegendary.png'),
    rare: require('./img/quality-bg/bgRare.png'),
    uncommon: require('./img/quality-bg/bgUncommon.png'),
  },
  shoebox: {
    common: require('./img/shoebox/common.png'),
    epic: require('./img/shoebox/epic.png'),
    legendary: require('./img/shoebox/legendary.png'),
    rare: require('./img/shoebox/rare.png'),
    uncommon: require('./img/shoebox/uncommon.png'),
  },
  stones: {
    luck1: require('./img/stones/1_3.png'),
    performance1: require('./img/stones/1_1.png'),
    recovery1: require('./img/stones/1_2.png'),
    speed1: require('./img/stones/1_4.png'),

    luck2: require('./img/stones/2_3.png'),
    performance2: require('./img/stones/2_1.png'),
    recovery2: require('./img/stones/2_2.png'),
    speed2: require('./img/stones/2_4.png'),

    luck3: require('./img/stones/3_3.png'),
    performance3: require('./img/stones/3_1.png'),
    recovery3: require('./img/stones/3_2.png'),
    speed3: require('./img/stones/3_4.png'),

    luck4: require('./img/stones/4_3.png'),
    performance4: require('./img/stones/4_1.png'),
    recovery4: require('./img/stones/4_2.png'),
    speed4: require('./img/stones/4_4.png'),

    luck5: require('./img/stones/5_3.png'),
    performance5: require('./img/stones/5_1.png'),
    recovery5: require('./img/stones/5_2.png'),
    speed5: require('./img/stones/5_4.png'),

    luck6: require('./img/stones/6_3.png'),
    performance6: require('./img/stones/6_1.png'),
    recovery6: require('./img/stones/6_2.png'),
    speed6: require('./img/stones/6_4.png'),

    luck7: require('./img/stones/7_3.png'),
    performance7: require('./img/stones/7_1.png'),
    recovery7: require('./img/stones/7_2.png'),
    speed7: require('./img/stones/7_4.png'),

    luck8: require('./img/stones/8_3.png'),
    performance8: require('./img/stones/8_1.png'),
    recovery8: require('./img/stones/8_2.png'),
    speed8: require('./img/stones/8_4.png'),

    luck9: require('./img/stones/9_3.png'),
    performance9: require('./img/stones/9_1.png'),
    recovery9: require('./img/stones/9_2.png'),
    speed9: require('./img/stones/9_4.png'),
  },
  manas: {
    manaCommonSmall: require('./img/mana/common1.png'),
    manaCommonMedium: require('./img/mana/common2.png'),
    manaCommonLarge: require('./img/mana/common3.png'),

    manaUncommonSmall: require('./img/mana/uncommon1.png'),
    manaUncommonMedium: require('./img/mana/uncommon2.png'),
    manaUncommonLarge: require('./img/mana/uncommon3.png'),

    manaRareSmall: require('./img/mana/rare1.png'),
    manaRareMedium: require('./img/mana/rare2.png'),
    manaRareLarge: require('./img/mana/rare3.png'),

    manaEpicSmall: require('./img/mana/epic1.png'),
    manaEpicMedium: require('./img/mana/epic2.png'),
    manaEpicLarge: require('./img/mana/epic3.png'),

    manaLegendarySmall: require('./img/mana/legendary1.png'),
    manaLegendaryMedium: require('./img/mana/legendary2.png'),
    manaLegendaryLarge: require('./img/mana/legendary3.png'),
  },

  BgNFT: require('./img/bg-nft-wallet.png'),
  BagIcon: require('./img/bag.png'),
  BagActiveIcon: require('./img/bag-active.png'),

  MarketIcon: require('./img/market.png'),
  MarketActiveIcon: require('./img/market-active.png'),
  MintBackground: require('./img/mint-bg.png'),
  Logo: require('./logo.png'),
}

export default Images
