/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react'
import { Button, CircularProgress, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  BAG_TABS,
  CUSTOM_EVENTS,
  MARKET_TABS,
  NFT_STATUS,
  NFT_TYPE,
} from 'types/common'
import InfiniteLoadingList from 'components/infinite-loading-list/InfiniteLoadingList'
import ProductItem from 'components/product-item/ProductItem'
import {
  setBagShoeDetail,
  fetchShoeBagList,
  fetchShoeBoxBagList,
  fetchManaBagList,
  fetchStoneBagList,
} from 'store/reducers/bag'
import { useAppDispatch, useAppSelector } from 'store'
import FilterTabs from 'components/filter-tabs/FilterTabs'
import { IFilterData } from 'components/filter-menu/FilterMenu'

import { ROUTE_PATHS } from 'routes'
import { IMana, IShoe, IShoeBox, IStone } from 'types/nft'
import ShoeboxModal from 'components/shoebox-modal/ShoeboxModal'
import ShoeboxOpen from 'components/shoebox-open/ShoeboxOpen'
import Dropdown from 'components/dropdown/Dropdown'
import { showToast } from 'store/reducers/common'
import Images from 'images'
import { commonClass } from 'theme'
import SellModal from 'components/sell-modal/SellModal'
import RevokeModal from 'components/revoke-modal/RevokeModal'
import styles from './BagList.styles'

const tabsOptions = [
  {
    value: BAG_TABS.SHOE,
    label: 'Sneaker',
  },
  {
    value: BAG_TABS.STONE,
    label: 'Stones',
  },
  {
    value: BAG_TABS.MANA,
    label: 'Mana',
  },
]

const shoeOptions = [
  {
    value: NFT_TYPE.SHOE,
    label: 'Shoe',
  },
  {
    value: NFT_TYPE.SHOEBOX,
    label: 'Shoebox',
  },
]

const BagList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const shoeList = useAppSelector((state) => state.bag.shoeData) || []
  const shoeBoxList = useAppSelector((state) => state.bag.shoeBoxData) || []
  const stoneList = useAppSelector((state) => state.bag.stoneData) || []
  const manaList = useAppSelector((state) => state.bag.manaData) || []

  const [shoeboxView, setShoeboxView] = useState<IShoeBox | null>(null)
  const [sellItem, setSellItem] = useState<IShoeBox | IStone | IMana | null>(
    null,
  )
  const [revokeItem, setRevokeItem] = useState<IStone | IMana | null>(null)

  const [shoeboxOpen, setShoeboxOpen] = useState<IShoeBox | null>(null)
  const [loading, setLoading] = useState(false)

  const [tabValue, setTabValue] = useState(BAG_TABS.SHOE)
  const [filter, setFilter] = useState<IFilterData>({
    type: 'SHOE',
  })

  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const reload = () => {
    let type = tabValue

    if (type === BAG_TABS.SHOE && filter.type) {
      type = filter.type
    }

    setLoading(true)

    if (type === NFT_TYPE.SHOE) {
      dispatch(
        fetchShoeBagList({
          limit: 30,
          offset: 0,
          type: type,
          filter: [],
        }),
      ).finally(() => {
        setLoading(false)
      })
    }
    if (type === NFT_TYPE.SHOEBOX) {
      dispatch(
        fetchShoeBoxBagList({
          limit: 200,
          offset: 0,
          type: type,
          filter: [],
        }),
      ).finally(() => {
        setLoading(false)
      })
    }

    if (type === NFT_TYPE.MANA) {
      dispatch(
        fetchManaBagList({
          limit: 200,
          offset: 0,
          filter: [],
        }),
      ).finally(() => {
        setLoading(false)
      })
    }

    if (type === NFT_TYPE.STONE) {
      dispatch(
        fetchStoneBagList({
          limit: 100,
          offset: 0,
          filter: [],
        }),
      ).finally(() => {
        setLoading(false)
      })
    }
  }

  const handleChangeTab = (value: string) => {
    if (value) {
      setTabValue(value)
      setFilter({ ...filter })
    }
  }

  const handleRefreshData = useCallback(() => {
    setFilter({ ...filter })
  }, [filter, tabValue])

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENTS.REFRESH_CLICKED, handleRefreshData)

    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.REFRESH_CLICKED,
        handleRefreshData,
      )
    }
  }, [handleRefreshData])

  const handleFetchMoreData = (page: number) => {}

  const handleItemClick = (
    item: IShoe | IShoeBox | IStone | IMana,
    type: string,
  ) => {
    if (type === NFT_TYPE.SHOE) {
      item = item as IShoe
      dispatch(setBagShoeDetail(item))
      navigate(ROUTE_PATHS.BAG_DETAIL.replace(':shoeId', item.shoeId + ''))
    }
    if (type === NFT_TYPE.SHOEBOX) {
      setShoeboxView(item as IShoeBox)
    }
    if (type === NFT_TYPE.MANA || type === NFT_TYPE.STONE) {
      if (item.status !== NFT_STATUS.SELLING) {
        setSellItem({
          ...(item as IStone | IMana),
          nftType: type,
        })
      } else {
        setRevokeItem({
          ...(item as IStone | IMana),
          nftType: type,
        })
      }
    }
  }

  const renderList = (
    list: IShoe[] | IShoeBox[] | IStone[] | IMana[],
    type: string,
  ) => {
    if (list.length === 0) {
      return (
        <div css={styles.empty}>
          {type === NFT_TYPE.SHOE && <img src={Images.Empty.Shoe} alt='' />}
          {type === NFT_TYPE.SHOEBOX && (
            <img src={Images.Empty.Shoebox} alt='' />
          )}
          {type === NFT_TYPE.STONE && <img src={Images.Empty.Stone} alt='' />}
          {type === NFT_TYPE.MANA && <img src={Images.Empty.Mana} alt='' />}

          <p>No items in bags.</p>

          <Button
            onClick={() => navigate(`${ROUTE_PATHS.MARKET}?type=${type}`)}
            css={[commonClass.outlineButton]}
          >
            Go to markets
          </Button>
        </div>
      )
    }

    return (
      <InfiniteLoadingList
        hasMore={false}
        fecthMoreData={handleFetchMoreData}
        items={list.map((shoeItem, idx) => {
          let uniqId = idx

          if (type === NFT_TYPE.SHOE) {
            uniqId = (shoeItem as IShoe).shoeId
          }

          if (type === NFT_TYPE.SHOEBOX) {
            uniqId = (shoeItem as IShoeBox).shoeBoxId
          }

          if (type === NFT_TYPE.MANA) {
            uniqId = (shoeItem as IMana).manaId
          }

          if (type === NFT_TYPE.STONE) {
            uniqId = (shoeItem as IStone).stoneId
          }

          return (
            <Grid key={uniqId} item xs={6} sm={4} md={3} lg={2.4}>
              <ProductItem
                itemDetail={shoeItem}
                type={type}
                onItemClick={() => handleItemClick(shoeItem, type)}
              />
            </Grid>
          )
        })}
      />
    )
  }

  const runOpen = (item: IShoeBox) => {
    setShoeboxOpen(item)
    setShoeboxView(null)
  }

  const runSellShoeBox = (item: IShoeBox) => {
    setSellItem({
      ...item,
      nftType: NFT_TYPE.SHOEBOX,
    })
    setShoeboxView(null)
  }
  const runTransfer = () => {}

  return (
    <div css={styles.wrapper}>
      <div css={styles.contentWrapper}>
        <div css={styles.topWrapper}>
          <div className={'dfjc mb-3'}>
            <FilterTabs
              value={tabValue}
              onChange={handleChangeTab}
              options={tabsOptions}
            />
          </div>
          {tabValue === MARKET_TABS.SHOE && (
            <div css={styles.listActionWrap}>
              <Dropdown
                value={filter.type}
                onChange={(e) => setFilter({ type: e })}
                options={shoeOptions}
                width={120}
                menuWidth={100}
              />
            </div>
          )}
        </div>
        <div css={styles.scrollList}>
          {tabValue === MARKET_TABS.SHOE &&
            (filter?.type === NFT_TYPE.SHOE || !filter?.type) &&
            renderList(shoeList, NFT_TYPE.SHOE)}

          {tabValue === MARKET_TABS.SHOE &&
            filter?.type === NFT_TYPE.SHOEBOX &&
            renderList(shoeBoxList, NFT_TYPE.SHOEBOX)}

          {tabValue === MARKET_TABS.STONE &&
            renderList(stoneList, NFT_TYPE.STONE)}
          {tabValue === MARKET_TABS.MANA && renderList(manaList, NFT_TYPE.MANA)}

          {loading && (
            <div css={styles.loading}>
              <CircularProgress />
            </div>
          )}
        </div>
      </div>

      <ShoeboxModal
        isOpen={!!shoeboxView}
        handleClose={() => setShoeboxView(null)}
        itemDetail={shoeboxView}
        onSell={() => {
          if (shoeboxView) {
            runSellShoeBox(shoeboxView)
          }
        }}
        onOpen={() => {
          if (shoeboxView) {
            runOpen(shoeboxView)
          }
        }}
        onTransfer={() => {
          setShoeboxView(null)
          runTransfer()
        }}
        onRevokeSuccess={() => {
          setShoeboxView(null)
          dispatch(
            showToast({
              title: 'Revoke successfully!',
            }),
          )
          reload()
        }}
      />

      <SellModal
        isOpen={!!sellItem}
        handleClose={() => setSellItem(null)}
        itemDetail={sellItem}
        onSellSuccess={() => {
          setSellItem(null)
          dispatch(
            showToast({
              title: 'Listing successfully!',
            }),
          )
          reload()
        }}
        type={sellItem?.nftType || ''}
      />

      <RevokeModal
        itemDetail={revokeItem}
        isOpen={!!revokeItem}
        type={revokeItem?.nftType as string}
        handleClose={() => setRevokeItem(null)}
        onRevokeSuccess={() => {
          setRevokeItem(null)
          dispatch(
            showToast({
              title: 'Revoke successfully!',
            }),
          )
          reload()
        }}
      />

      <ShoeboxOpen
        isOpen={!!shoeboxOpen}
        handleClose={() => setShoeboxOpen(null)}
        itemDetail={shoeboxOpen}
        onOpenSuccess={() => {
          setShoeboxOpen(null)
          reload()
        }}
      />
    </div>
  )
}

export default BagList
