import _toUpperCase from 'lodash/toUpper'
import { NFT_TYPE } from 'types/common'
import { IMana, IShoe, IStone } from 'types/nft'
import { ICaloShoe } from './types'

export const convertStructureShoe = (old: ICaloShoe): IShoe => {
  return {
    shoeId: old.shoeId,
    mint: old.mint,
    durable: old.durable,
    level: old.level,
    point: old.point,
    status: _toUpperCase(old.status),
    quality: old.quality,
    class: old.class,
    image: old.image,
    leftShoe: old.leftShoe,
    rightShoe: old.rightShoe,
    mintTo: old.mintTo,
    // gamesCanPlaying: old.xxxxxxxxxx,
    marketSellTime: old.sellTime,
    marketSellingPrice: old.sellingPrice,

    attributes: old.properties,
    sockets: old.sockets,
    apiStatus: '',
    isRunning: old.isRunning,
    cooldownTime: old.cooldownTime,
    lifetime: old.lifetime,
    upgradeCost: old.upgradeCost,
    upgradeLevelingTime: old.levelingTime,
    upgradeSpeedUpCost: old.speedUpCost,
    levelUpTime: old.levelUpTime,

    nftType: NFT_TYPE.SHOE,
  }
}

export const convertStuctureStone = (old: IStone): IStone => ({
  ...old,
  status: _toUpperCase(old.status),
})

export const convertStuctureMana = (old: IMana): IMana => ({
  ...old,
  status: _toUpperCase(old.status),
})
