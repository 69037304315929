import { NFT_TYPE } from 'types/common'
import { IMarketListItem } from 'types/market'
import { convertStructureShoe, convertStuctureStone } from './nft'
import { ICaloMarketListItem, ICaloShoe } from './types'

export const convertStructureMarketListItem = (
  old: ICaloMarketListItem,
): IMarketListItem => {
  return {
    marketItemId: old.marketItemId,
    owner: old.owner,
    status: old.status,
    type: old.type,
    price: old.price,
    base:
      old.type === NFT_TYPE.SHOE
        ? convertStructureShoe(old.base as ICaloShoe)
        : old.type !== NFT_TYPE.SHOEBOX
        ? convertStuctureStone(old.base as any)
        : (old.base as any),
  }
}
