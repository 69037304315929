/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Link } from 'react-router-dom'
import { ArrowLeft } from 'iconsax-react'
import { fetchShoeOnchainBalance } from 'store/reducers/walletOnchain'

import { useAppDispatch, useAppSelector } from 'store'
import { commonClass } from 'theme'
import Images from 'images'
import styles from './NFTShoeboxList.styles'
import ShoeCard from './ShoeCard'

const NFTShoeList = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const walletOnchain = useAppSelector((state) => state.walletOnchain)

  useEffect(() => {
    if (account) {
      dispatch(fetchShoeOnchainBalance(account))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <div css={[commonClass.containerSmall, styles.wrapper]}>
      <div css={styles.pageHeader}>
        <Link to='/wallet/onchain' css={styles.iconBack}>
          <ArrowLeft size='24' color='#D0E0F7' className='spending' />
        </Link>
        <h3>Sneakers</h3>
      </div>

      {walletOnchain?.shoe?.length > 0 ? (
        <div css={styles.contentWrapper}>
          {walletOnchain?.shoe?.map((s) => (
            <ShoeCard
              itemDetail={s}
              image={s.image}
              shoeId={s.shoe_id}
              quality={s.quality}
            />
          ))}
        </div>
      ) : (
        <div css={styles.noItem}>
          <img src={Images.Empty.Shoe} alt='No Shoe' />
          <div css={styles.noItemText}>No items in bags.</div>
        </div>
      )}
    </div>
  )
}

export default NFTShoeList
