/** @jsxImportSource @emotion/react */
import _get from 'lodash/get'
import { Box, Button, Drawer, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from 'helpers/utils'
import { baseColors, commonClass } from 'theme'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { ROUTE_PATHS } from 'routes'
import styles from './ModalShoeboxAction.styles'

interface IProps {
  isOpen: boolean
  handleClose: () => void
  quality: string
  image: string
  shoeId: number
}

const ModalShoeAction = ({
  isOpen,
  quality,
  handleClose,
  image,
  shoeId,
}: IProps) => {
  const qualityText = capitalizeFirstLetter(quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')

  const navigate = useNavigate()

  return (
    <Drawer anchor='bottom' open={isOpen} onClose={handleClose}>
      <Box css={styles.modalWrapper}>
        <div className='df' css={styles.contentHeader}>
          <Typography color={qualityColor} css={styles.title}>
            {qualityText} Sneaker
          </Typography>
          <div className='pointer' onClick={handleClose}>
            <Images.CloseIcon />
          </div>
        </div>
        <div css={styles.contentBody}>
          <WrapperBgImg src={Images.BgNFT}>
            <img css={styles.nftImg} src={image} alt='nft' />
            <Typography color={qualityColor} css={styles.id}>
              #{shoeId}
            </Typography>
          </WrapperBgImg>
        </div>
        <div className='df jcb mx-auto px-4 py-4'>
          <Button
            css={[commonClass.outlineButton, styles.cancelBtn]}
            onClick={() =>
              navigate(
                ROUTE_PATHS.WALLET_TRANSFER_EXTERNAL_SHOE.replace(
                  ':shoeId',
                  `${shoeId}`,
                ),
              )
            }
          >
            SEND
          </Button>
          <Button
            css={[commonClass.appButton, styles.confirmBtn]}
            onClick={() => navigate(ROUTE_PATHS.WALLET_TRANSFER)}
          >
            TO INVENTORY
          </Button>
        </div>
      </Box>
    </Drawer>
  )
}

export default ModalShoeAction
