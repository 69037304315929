import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import { Request } from 'helpers/request'
import { ethersToSerializedBigNumber } from 'utils/bigNumber'
import { simpleRpcProvider } from 'utils/providers'
import {
  getCaloContract,
  getFitContract,
  getShoeContract,
  getShoeboxContract,
} from 'utils/contractHelpers'

export const fetchCaloOnchainBallance = createAsyncThunk(
  'walletOnchain/caloBalance',
  async (account: string) => {
    try {
      const caloContract = getCaloContract()
      const res = await caloContract.balanceOf(account)

      return {
        data: Number(ethers.utils.formatEther(res.toString())),
      }
    } catch (err) {
      return { data: 0 }
    }
  },
)

export const fetchFitOnchainBallance = createAsyncThunk(
  'walletOnchain/fitBalance',
  async (account: string) => {
    try {
      const fitContract = getFitContract()
      const res = await fitContract.balanceOf(account)

      return {
        data: Number(ethers.utils.formatEther(res.toString())),
      }
    } catch (err) {
      return { data: 0 }
    }
  },
)

export const fetchShoeOnchainBalance = createAsyncThunk(
  'walletOnchain/shoeBalance',
  async (account: string) => {
    try {
      const shoeContract = getShoeContract()
      const tx = await shoeContract.balanceOf(account)
      const total = parseInt(ethersToSerializedBigNumber(tx))
      const results = []

      const ids: any[] = []

      for (let index = 0; index < total; index++) {
        results.push(shoeContract.tokenOfOwnerByIndex(account, index))
      }

      const allPromises: any = await Promise.all(results)

      allPromises.forEach((item: ethers.BigNumber) => {
        ids.push(ethersToSerializedBigNumber(item))
      })

      const apiArr: any[] = []

      ids.forEach((id) => {
        apiArr.push(
          Request.call<any>({
            url: `/shoe/${id}`,
            method: 'GET',
          }),
        )
      })

      // const allPromisesApi = await Promise.allSettled(apiArr)

      const data = await Promise.all(apiArr)

      return {
        data: data,
      }
    } catch (err) {
      return { data: [] }
    }
  },
)

export const fetchShoeBoxOnchainBalance = createAsyncThunk(
  'walletOnchain/shoeboxBalance',
  async (account: string) => {
    try {
      const shoeboxContract = getShoeboxContract()
      const tx = await shoeboxContract.balanceOf(account)
      const total = parseInt(ethersToSerializedBigNumber(tx))
      const results = []

      const ids: any[] = []

      for (let index = 0; index < total; index++) {
        results.push(shoeboxContract.tokenOfOwnerByIndex(account, index))
      }

      const allPromises: any = await Promise.all(results)

      allPromises.forEach((item: ethers.BigNumber) => {
        ids.push(ethersToSerializedBigNumber(item))
      })

      const apiArr: any[] = []

      ids.forEach((id) => {
        apiArr.push(
          Request.call({
            url: `/shoebox/${id}`,
            method: 'GET',
          }),
        )
      })

      // const allPromisesApi = await Promise.allSettled(apiArr);
      const data = await Promise.all(apiArr)

      return {
        data: data,
      }
    } catch (err) {
      return { data: [] }
    }
  },
)

export const fetchBNBBalance = createAsyncThunk(
  'walletOnchain/bnbBalance',
  async (account: string) => {
    try {
      const res = await simpleRpcProvider.getBalance(account)

      return {
        data: Number(ethers.utils.formatEther(res.toString())),
      }
    } catch (err) {
      return { data: 0 }
    }
  },
)

interface WalletOnchainState {
  fit: number
  calo: number
  bnb: number
  shoe: any[]
  shoebox: any[]
}

const initialState: WalletOnchainState = {
  fit: 0,
  calo: 0,
  bnb: 0,
  shoe: [],
  shoebox: [],
}

const walletSlice = createSlice({
  name: 'walletOnchain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCaloOnchainBallance.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        state.calo = data || 0
      } else {
        state.calo = 0
      }
    })
    builder.addCase(fetchFitOnchainBallance.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        state.fit = data || 0
      } else {
        state.fit = 0
      }
    })
    builder.addCase(fetchBNBBalance.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        state.bnb = data || 0
      } else {
        state.bnb = 0
      }
    })
    builder.addCase(fetchShoeOnchainBalance.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        state.shoe = data || []
      } else {
        state.shoe = []
      }
    })
    builder.addCase(fetchShoeBoxOnchainBalance.fulfilled, (state, action) => {
      const { data } = action.payload

      if (data) {
        state.shoebox = data || []
      } else {
        state.shoebox = []
      }
    })
  },
})

export default walletSlice.reducer
