/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, Drawer, CircularProgress } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import _toUpperCase from 'lodash/toUpper'
import {
  CUSTOM_EVENTS,
  MARKET_SORT_VALUES,
  MARKET_TABS,
  NFT_TYPE,
} from 'types/common'
import InfiniteLoadingList from 'components/infinite-loading-list/InfiniteLoadingList'
import ProductItem from 'components/product-item/ProductItem'
import BuyModal from 'components/buy-modal/BuyModal'
import { IMarketListItem } from 'types/market'
import { fetchMarketList, setMarketShoeDetail } from 'store/reducers/marketList'
import { useAppDispatch, useAppSelector } from 'store'
import FilterTabs from 'components/filter-tabs/FilterTabs'
import Dropdown from 'components/dropdown/Dropdown'
import FilterButton from 'components/filter-button/FilterButton'
import { isMobile } from 'helpers/utils'
import FilterMenu, { IFilterData } from 'components/filter-menu/FilterMenu'

import { ROUTE_PATHS } from 'routes'
import { showToast } from 'store/reducers/common'
import Images from 'images'
import styles from './MarketList.styles'

const tabsOptions = [
  {
    value: MARKET_TABS.SHOE,
    label: 'Sneaker',
  },
  {
    value: MARKET_TABS.STONE,
    label: 'Stones',
  },
  {
    value: MARKET_TABS.MANA,
    label: 'Mana',
  },
]

const sortOptions = [
  {
    value: MARKET_SORT_VALUES.PRICE_ASC,
    label: 'Lowest Price',
  },
  {
    value: MARKET_SORT_VALUES.PRICE_DESC,
    label: 'Highest Price',
  },
  {
    value: MARKET_SORT_VALUES.LATEST,
    label: 'Latest',
  },
]

const MarketList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const shoeList = useAppSelector((state) => state.marketList.shoeData) || []
  const hasMore = useAppSelector((state) => state.marketList.hasMore)
  const shoeBoxList =
    useAppSelector((state) => state.marketList.shoeBoxData) || []
  const manaList = useAppSelector((state) => state.marketList.manaData) || []
  const stoneList = useAppSelector((state) => state.marketList.stoneData) || []
  const typeQuery = searchParams.get('type')
  const limitPerPage = 30
  const [randomKey, setRandomKey] = useState(Math.random())
  const scrollListRef = useRef(null)
  let initTab = MARKET_TABS.SHOE
  let initFilter = { type: NFT_TYPE.SHOE }

  if (typeQuery) {
    initTab = typeQuery
    initFilter = { type: typeQuery }
    if (typeQuery === NFT_TYPE.SHOE || typeQuery === NFT_TYPE.SHOEBOX) {
      initTab = MARKET_TABS.SHOE
    }
  }

  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(initTab)
  const [sortValue, setSortValue] = useState(MARKET_SORT_VALUES.LATEST)
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [buyItem, setBuyItem] = useState<IMarketListItem | null>(null)

  const [filter, setFilter] = useState<IFilterData>(initFilter)

  const generateFilterObj = () => {
    const filterData = []

    if (filter.level) {
      filterData.push({
        field: 'level',
        operator: '$gte',
        value: filter.level[0],
      })
      filterData.push({
        field: 'level',
        operator: '$lte',
        value: filter.level[1],
      })
    }

    if (filter && filter.mint) {
      filterData.push({
        field: 'mint',
        operator: '$gte',
        value: filter.mint[0],
      })
      filterData.push({
        field: 'mint',
        operator: '$lte',
        value: filter.mint[1],
      })
    }
    if (filter.lifeTime) {
      filterData.push({
        field: 'lifetime',
        operator: '$gte',
        value: filter.lifeTime[0],
      })
      filterData.push({
        field: 'lifetime',
        operator: '$lte',
        value: filter.lifeTime[1],
      })
    }
    if (filter.quality) {
      filterData.push({
        field: 'quality',
        operator: '$eq',
        value: filter.quality,
      })
    }

    if (filter.size) {
      filterData.push({
        field: 'size',
        operator: '$eq',
        value: filter.size,
      })
    }

    if (filter.class) {
      filterData.push({
        field: 'class',
        operator: '$eq',
        value: filter.class,
      })
    }

    if (filter.stoneQuality) {
      filterData.push({
        field: 'attribute',
        operator: '$gte',
        value: filter.stoneQuality[0],
      })
      filterData.push({
        field: 'attribute',
        operator: '$lte',
        value: filter.stoneQuality[1],
      })
    }

    if (filter.stoneType) {
      filterData.push({
        field: 'type',
        operator: '$eq',
        value: _toUpperCase(filter.stoneType),
      })
    }

    const sortData = []

    if (sortValue === MARKET_SORT_VALUES.PRICE_ASC) {
      sortData.push({
        field: 'price',
        order: 'ASC',
      })
    }
    if (sortValue === MARKET_SORT_VALUES.PRICE_DESC) {
      sortData.push({
        field: 'price',
        order: 'DESC',
      })
    }
    if (sortValue === MARKET_SORT_VALUES.LATEST) {
      sortData.push({
        field: 'createdTime',
        order: 'DESC',
      })
    }

    let type = tabValue

    if (type === MARKET_TABS.SHOE && filter.type) {
      type = filter.type
    }

    return {
      type,
      filterData,
      sortData,
    }
  }

  const reloadData = () => {
    const currentRef = scrollListRef.current as any

    if (currentRef) {
      currentRef.scrollTo(0, 0)
    }
    setLoading(true)
    const { type, filterData, sortData } = generateFilterObj()

    setRandomKey(Math.random())
    dispatch(
      fetchMarketList({
        limit: limitPerPage,
        offset: 0,
        type: type,
        filter: filterData,
        sort: sortData,
      }),
    ).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    reloadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sortValue])

  const handleChangeTab = (value: string) => {
    if (value) {
      setTabValue(value)
      setFilter({ type: value === MARKET_TABS.SHOE ? value : undefined })
    }
  }

  const handleRefreshData = useCallback(() => {
    setFilter({ ...filter })
  }, [filter])

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENTS.REFRESH_CLICKED, handleRefreshData)

    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.REFRESH_CLICKED,
        handleRefreshData,
      )
    }
  }, [handleRefreshData])

  const handleFetchMoreData = (page: number) => {
    const { type, filterData, sortData } = generateFilterObj()

    dispatch(
      fetchMarketList({
        limit: limitPerPage,
        offset: page * limitPerPage,
        type: type,
        filter: filterData,
        sort: sortData,
      }),
    ).finally(() => {
      setLoading(false)
    })
  }

  const handleItemClick = (item: IMarketListItem, type: string) => {
    if (type === NFT_TYPE.SHOE) {
      dispatch(setMarketShoeDetail(item))
      navigate(
        ROUTE_PATHS.MARKET_DETAIL.replace(
          ':marketItemId',
          item.marketItemId + '',
        ),
      )
    } else {
      handleBuyItemClick(item)
    }
  }

  const handleBuyItemClick = (item: IMarketListItem) => {
    if (item?.owner) {
      return dispatch(
        showToast({
          title: 'You are the owner of this item',
        }),
      )
    }
    setBuyItem(item)
  }

  const handleCloseBuyModal = () => {
    setBuyItem(null)
  }

  const handleSortValue = (value: any) => {
    setSortValue(value)
  }

  const handleClickOpenFilter = () => {
    setIsOpenFilter(true)
  }

  const handleCloseFilter = () => {
    setIsOpenFilter(false)
  }

  const renderFilterMenu = () => {
    return (
      <FilterMenu
        value={filter}
        onSave={(data: IFilterData) => {
          setFilter(data)
          handleCloseFilter()
        }}
        type={tabValue}
        onChange={(data: IFilterData) => {
          if (isMobile()) return
          setFilter(data)
          handleCloseFilter()
        }}
      />
    )
  }

  const onBuySuccess = () => {
    dispatch(
      showToast({
        title: 'Transaction Successful!',
        message: 'Check your bag to start using the item.',
      }),
    )
    handleCloseBuyModal()
    handleRefreshData()
  }

  const renderList = (list: IMarketListItem[], type: string) => {
    if (list.length === 0) {
      return (
        <div css={styles.empty}>
          {type === NFT_TYPE.SHOE && <img src={Images.Empty.Shoe} alt='' />}
          {type === NFT_TYPE.SHOEBOX && (
            <img src={Images.Empty.Shoebox} alt='' />
          )}
          {type === NFT_TYPE.STONE && <img src={Images.Empty.Stone} alt='' />}
          {type === NFT_TYPE.MANA && <img src={Images.Empty.Mana} alt='' />}
          <p>No items available.</p>
        </div>
      )
    }

    return (
      <InfiniteLoadingList
        key={randomKey}
        hasMore={list.length >= limitPerPage && hasMore}
        fecthMoreData={handleFetchMoreData}
        items={list.map((shoeItem) => (
          <Grid key={shoeItem.marketItemId} item xs={6} sm={4} md={3} lg={2.4}>
            <ProductItem
              itemDetail={shoeItem.base}
              price={shoeItem.price}
              type={type}
              onItemClick={() => handleItemClick(shoeItem, type)}
              onBuyClick={() => handleBuyItemClick(shoeItem)}
              isBuyItem={true}
              owner={shoeItem.owner}
            />
          </Grid>
        ))}
      />
    )
  }

  return (
    <div css={styles.wrapper}>
      {isMobile() ? (
        <Drawer
          anchor={'right'}
          open={isOpenFilter}
          onClose={handleCloseFilter}
        >
          <div css={styles.filterMobileWrapper}>{renderFilterMenu()}</div>
        </Drawer>
      ) : (
        <div css={styles.filterWrapper}>{renderFilterMenu()}</div>
      )}
      <div css={styles.contentWrapper}>
        <div css={styles.topWrapper}>
          <div className={'dfjc mb-3'}>
            <FilterTabs
              value={tabValue}
              onChange={handleChangeTab}
              options={tabsOptions}
            />
          </div>

          <div css={styles.listActionWrap}>
            <Dropdown
              value={sortValue}
              onChange={handleSortValue}
              options={sortOptions}
              width={120}
              menuWidth={100}
            />
            <div css={styles.filterButton}>
              <FilterButton
                total={Object.values(filter).filter((a) => a).length}
                onClick={handleClickOpenFilter}
              />
            </div>
          </div>
        </div>
        <div css={styles.scrollList} ref={scrollListRef} key={randomKey}>
          {tabValue === MARKET_TABS.SHOE &&
            (filter?.type === NFT_TYPE.SHOE || !filter?.type) &&
            renderList(shoeList, NFT_TYPE.SHOE)}

          {tabValue === MARKET_TABS.SHOE &&
            filter?.type === NFT_TYPE.SHOEBOX &&
            renderList(shoeBoxList, NFT_TYPE.SHOEBOX)}

          {tabValue === MARKET_TABS.STONE &&
            renderList(stoneList, NFT_TYPE.STONE)}
          {tabValue === MARKET_TABS.MANA && renderList(manaList, NFT_TYPE.MANA)}
          {loading && (
            <div css={styles.loading}>
              <CircularProgress />
            </div>
          )}
        </div>

        <BuyModal
          itemDetail={buyItem}
          isOpen={!!buyItem?.type}
          handleClose={handleCloseBuyModal}
          onBuySuccess={onBuySuccess}
          type={buyItem?.type || ''}
        />
      </div>
    </div>
  )
}

export default MarketList
