/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress, Typography } from '@mui/material'
import _toLower from 'lodash/toLower'
import _get from 'lodash/get'
import { useCallback, useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import { IMarketListItem } from 'types/market'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import {
  capitalizeFirstLetter,
  getManaImage,
  getStoneImage,
  getStoneTypeColor,
} from 'helpers/utils'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import ProgressBar from 'components/progress-bar/ProgressBar'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { buyMarketItem } from 'store/reducers/marketList'
import { IMana, IShoe, IShoeBox, IStone } from 'types/nft'
import { fetchWalletBallance } from 'store/reducers/wallet'
import { NFT_TYPE } from 'types/common'
import MintShoes from 'components/shoe-detail/MintShoes'
import { fetchShoeBoxDetail } from 'store/reducers/bag'
import styles from './BuyModal.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  itemDetail: IMarketListItem | null
  onBuySuccess: () => void
  type: string
}

const BuyModal = ({
  isOpen,
  handleClose,
  itemDetail,
  onBuySuccess,
  type,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const getShoeBoxDetail = useCallback(() => {
    const shoeBoxData = itemDetail?.base as IShoeBox

    if (shoeBoxData?.shoeBoxId && itemDetail?.base) {
      dispatch(fetchShoeBoxDetail({ shoeBoxId: shoeBoxData.shoeBoxId }))
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            itemDetail.base = response
          }
        })
    }
  }, [itemDetail, dispatch])

  useEffect(() => {
    if (isOpen && itemDetail?.type === NFT_TYPE.SHOEBOX) {
      getShoeBoxDetail()
    }
  }, [itemDetail, isOpen, getShoeBoxDetail])

  const handleConfirm = () => {
    if (itemDetail?.marketItemId) {
      setLoading(true)
      dispatch(
        buyMarketItem({
          marketItemId: itemDetail.marketItemId,
        }),
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            dispatch(fetchWalletBallance({}))
            onBuySuccess()
          } else {
            dispatch(
              showToast({
                title: 'Error',
                message: response.message,
              }),
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const renderDetailItem = () => {
    if (type === NFT_TYPE.SHOE) {
      return renderShoeDetail(itemDetail?.base as IShoe)
    }

    if (type === NFT_TYPE.SHOEBOX) {
      return renderShoeBoxDetail(itemDetail?.base as IShoeBox)
    }

    if (type === NFT_TYPE.STONE) {
      return renderStoneDetail(itemDetail?.base as IStone)
    }

    if (type === NFT_TYPE.MANA) {
      return renderManaDetail(itemDetail?.base as IMana)
    }

    return null
  }

  const renderShoeDetail = (itemDetail: IShoe) => {
    const qualityText = capitalizeFirstLetter(itemDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')
    const qualityBg = (Images.qualityBg as any)[
      _toLower(itemDetail?.quality) || 'common'
    ] as string

    return (
      <>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img css={styles.shoeImg} src={itemDetail?.image} alt='' />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{itemDetail?.shoeId}
              </div>

              <div className='mb-3 mx-auto' css={styles.shoeInfoWrapper}>
                <ShoeInfo
                  className='mx-auto'
                  level={itemDetail?.level}
                  shoeClass={itemDetail?.class}
                  durable={itemDetail?.durable}
                  color={qualityColor}
                />
              </div>
            </div>
          </WrapperBgImg>
        </div>
        <div css={styles.mintWrapper} className='mt-3 mx-auto mb-5'>
          <div css={styles.mintText}>Mint {itemDetail?.mint}/7</div>
          <ProgressBar
            completeColor={qualityColor}
            split
            value={itemDetail?.mint}
            maxValue={7}
          />
        </div>
      </>
    )
  }

  const renderStoneDetail = (itemDetail: IStone) => {
    const stoneImg = getStoneImage(itemDetail.type, itemDetail.level)
    const typeText = capitalizeFirstLetter(itemDetail.type)
    const colorType = getStoneTypeColor(typeText)

    return (
      <>
        <div css={styles.bgWrapper}>
          <div css={styles.bgWrapperContent}>
            <img css={styles.shoeImg} src={stoneImg} alt='' className='m-0' />

            <div
              className='mb-2'
              css={styles.shoeId}
              style={{ color: colorType }}
            >
              #{itemDetail?.stoneId}
            </div>

            <Typography css={styles.shoeId} color={baseColors.neutralColor1}>
              +{itemDetail.attribute} | +{itemDetail.awakeningEffect}% | Level{' '}
              {itemDetail.level}
            </Typography>
          </div>
        </div>
      </>
    )
  }

  const renderManaDetail = (itemDetail: IMana) => {
    const qualityText = capitalizeFirstLetter(itemDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')

    const manaImg = getManaImage(itemDetail.quality, itemDetail.size)

    return (
      <>
        <div css={styles.bgWrapper}>
          <div css={styles.bgWrapperContent}>
            <img css={styles.shoeImg} src={manaImg} alt='' className='m-0' />

            <div
              className='mb-2'
              css={styles.shoeId}
              style={{ color: qualityColor }}
            >
              #{itemDetail?.manaId}
            </div>
            <Typography css={styles.shoeId} color={baseColors.neutralColor1}>
              {capitalizeFirstLetter(itemDetail.size)} | +{itemDetail.point}{' '}
              point
            </Typography>
          </div>
        </div>
      </>
    )
  }

  const renderShoeBoxDetail = (shoeboxDetail: IShoeBox) => {
    const qualityText = capitalizeFirstLetter(shoeboxDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')
    const qualityBg = (Images.qualityBg as any)[
      _toLower(shoeboxDetail?.quality) || 'common'
    ] as string

    const shoeboxImg = (Images.shoebox as any)[
      _toLower(shoeboxDetail?.quality) || 'common'
    ] as string

    return (
      <>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img
                css={styles.shoeImg}
                src={shoeboxImg}
                alt=''
                className='m-0'
              />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{shoeboxDetail?.shoeBoxId}
              </div>
            </div>
          </WrapperBgImg>
        </div>

        <MintShoes
          style={{ marginTop: 16 }}
          mints={[shoeboxDetail?.leftShoe, shoeboxDetail?.rightShoe]}
          title='Minted From'
          wrapStyle={{ backgroundColor: baseColors.imgBg }}
        />
      </>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={styles.wrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Buying</div>
          <div className='pointer' onClick={handleClose}>
            <Images.CloseIcon />
          </div>
        </div>
        {renderDetailItem()}

        <div css={styles.priceWrapper} className='mt-5 mx-auto'>
          <span>Cost</span>
          <div className='dfc'>
            <img src={Images.FitTokenIcon} alt='token-icon' />
            {itemDetail?.price} FIT
          </div>
        </div>

        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.outlineButton, styles.cancelBtn]}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleConfirm}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={loading}
          >
            {!!loading && <CircularProgress size={18} />}
            CONFIRM
          </Button>
        </div>
      </Box>
    </Modal>
  )
}

export default BuyModal
