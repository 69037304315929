import { css } from '@emotion/react'
import { baseColors, tabletWidth } from 'theme'

const styles = {
  wrapper: css`
    box-sizing: border-box;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    * {
      box-sizing: border-box;
    }
  `,
  content: css`
    flex: 1;
    overflow: auto;
  `,
  title: css`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 0 24px;
    span {
      color: ${baseColors.darkPrimary};
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  label: css`
    font-size: 12px;
    color: ${baseColors.filterLabel};
    letter-spacing: 0.4px;
  `,
  item: css`
    padding: 0 24px;
  `,
  selectList: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  `,
  selectItem: css`
    width: calc(50% - 16px);
    background: #171828;
    border-radius: 30px;
    margin: 4px 8px;
    text-align: center;
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
  `,
  selectItemActive: css`
    background: ${baseColors.primaryColor};
  `,

  actions: css`
    display: flex;
    align-items: center;
    padding: 12px;

    @media only screen and (min-width: ${tabletWidth}px) {
      display: none;
    }
  `,
  actionClear: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    margin: 4px 8px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    padding: 0;
    cursor: pointer;
  `,
  actionSave: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    margin: 4px 8px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    padding: 0;
    cursor: pointer;
  `,

  slider: css`
    /* padding: 12p; */
  `,
  minMax: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;

      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #f2f2f2;
    }
  `,
}

export default styles
