import { css } from '@emotion/react'
import { baseColors, tabletWidth } from 'theme'

const styles = {
  tabWrapper: css`
    background: ${baseColors.cardBg};
    border-radius: 24px;
    height: 40px;
    padding: 2px;
    width: 80%;
    display: flex;
    justify-content: space-between;

    @media only screen and (min-width: ${tabletWidth}px) {
      width: 400px;
    }
  `,
  tabBtn: css`
    &.MuiToggleButton-root {
      background-color: transparent;
      color: rgba(255, 255, 255, 0.5);
      width: 33.33%;
      text-transform: initial;
      border: 0;
      font-weight: 600;
      font-size: 14px;
      border-radius: 24px;
      &.Mui-selected {
        background-color: #333353;
        color: ${baseColors.neutralColor1};
        border-radius: 24px;
      }
    }
  `,
}

export default styles
