import { css } from '@emotion/react'
import { baseColors } from 'theme'

const styles = {
  menuWrapper: css`
    width: 300px;
    height: 100vh;
    background-color: ${baseColors.cardBg};
    padding: 16px;
    color: ${baseColors.neutralColor1};
    position: relative;
  `,
  userInfo: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  `,
  userFullName: css``,
  userEmail: css``,

  walletInfo: css`
    background-color: ${baseColors.imgBg};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0 12px;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    border-top: 1px solid #2c395b;
    &:first-of-type {
      border: 0;
    }
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  `,
  navigateMenu: css`
    background-color: ${baseColors.imgBg};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 0 12px;
  `,
  menuItem: css`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    border-top: 1px solid #2c395b;
    cursor: pointer;
    &:first-of-type {
      border: 0;
    }

    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
    &.active {
      color: ${baseColors.primaryColor3};
      font-weight: bold;
      svg path {
        stroke: ${baseColors.primaryColor3};
      }
    }

    svg {
      margin-left: auto;
    }
  `,
  bottomMenuWrapper: css`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%);
  `,
  signOutBtn: css`
    width: 126px;
  `,
}

export default styles
