import { css } from '@emotion/react'
import { baseColors, mobileWidth } from 'theme'
const styles = {
  wrapper: css`
    background-color: ${baseColors.cardBg};
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(95vw - 48px);
    border-radius: 16px;

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
    }
  `,
  animationWrap: css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    .svg-wrapper {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    .box-img {
      position: fixed;
      width: 40%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 1;
      animation: tilt-shaking 3s forwards;
    }
    .shoe-img {
      animation: fadeIn 2s forwards;
      animation-delay: 3s;
      position: fixed;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 0;
      z-index: 2;
    }
    @media only screen and (min-width: ${mobileWidth}px) {
      .svg-wrapper {
        width: 80%;
        height: 80%;
      }
      .shoe-img {
        width: 40%;
      }
      .box-img {
        width: 30%;
      }
    }
    @keyframes fadeIn {
      0% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 0;
      }
      100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
    @keyframes tilt-shaking {
      0%,
      25%,
      50%,
      75% {
        transform: translate(-50%, -50%) rotate(0deg);
        opacity: 1;
      }
      5%,
      30%,
      55%,
      80% {
        transform: translate(-50%, -50%) rotate(5deg);
        opacity: 1;
      }
      10%,
      40%,
      65%,
      90% {
        transform: translate(-50%, -50%) rotate(0eg);
        opacity: 1;
      }
      15%,
      45%,
      70%,
      95% {
        transform: translate(-50%, -50%) rotate(-5deg);
        opacity: 1;
      }
      100% {
        transform: translate(-50%, -50%) rotate(0deg);
        opacity: 0;
      }
    }
  `,

  bgWrapper: css`
    width: calc(95vw - 48px);
    height: calc((95vw - 48px) * 0.6615);

    @media only screen and (min-width: ${mobileWidth}px) {
      width: 500px;
      height: calc(500px * 0.6615);
    }
  `,
  bgWrapperContent: css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  `,
  shoeImg: css`
    width: 50%;
    margin-bottom: -30px;
  `,
  shoeId: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
  `,

  modalTitle: css`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};
  `,

  wrapperImg: css`
    height: 600px;
  `,
  mintWrapper: css`
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  mintText: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${baseColors.secondaryColor2};
    margin-bottom: 2px;
  `,

  priceWrapper: css`
    width: calc(100% - 12px);
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: ${baseColors.neutralColor1};

    img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  `,

  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    max-width: 400px;
  `,
  cancelBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  confirmBtn: css`
    width: calc(50% - 16px);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    height: 45px;
    cursor: pointer;
  `,
  shoeInfoWrapper: css`
    width: 90%;
  `,
  priceInputWrapper: css`
    margin-top: 16px;
  `,
  priceLabel: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  `,
  priceInput: css`
    width: 100%;
    height: 52px;
    .MuiInputBase-root {
      background: ${baseColors.appBg};
      border-radius: 12px;
      margin-top: 12px;
    }
    * {
      color: ${baseColors.neutralColor1} !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  `,

  confirmInfo: css`
    width: 100%;
  `,
  confirmInfoItem: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 24px;
    span:first-of-type {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;

      color: ${baseColors.neutralColor6};
    }
    span:last-of-type {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }
  `,
}

export default styles
