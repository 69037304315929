/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import _toLower from 'lodash/toLower'
import _get from 'lodash/get'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import { capitalizeFirstLetter } from 'helpers/utils'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { NFT_TYPE } from 'types/common'
import { IShoe } from 'types/nft'
import { fetchShoeBagList } from 'store/reducers/bag'
import styles from './SelectShoeModal.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  onSuccess: (shoe: IShoe) => void
  filters?: Array<any>
}

const SelectShoeModal = ({
  isOpen,
  handleClose,
  onSuccess,
  filters,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [shoeList, setShoeList] = useState<IShoe[]>([])
  const [shoeView, setShoeView] = useState<IShoe | undefined>()

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setShoeView(undefined)
    dispatch(
      fetchShoeBagList({
        limit: 30,
        offset: 0,
        type: NFT_TYPE.SHOE,
        filter: filters || [],
      }),
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setShoeList(response.data)
        } else {
          dispatch(
            showToast({
              title: 'Error',
              message: response.message,
            }),
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [isOpen])

  const renderShoeDetail = (shoeDetail: IShoe) => {
    const qualityText = capitalizeFirstLetter(shoeDetail?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')
    const qualityBg = (Images.qualityBg as any)[
      _toLower(shoeDetail?.quality) || 'common'
    ] as string

    return (
      <>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img css={styles.shoeImg} src={shoeDetail?.image} alt='' />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{shoeDetail?.shoeId}
              </div>

              <div className='mb-3 mx-auto' css={styles.shoeInfoWrapper}>
                <ShoeInfo
                  className='mx-auto'
                  level={shoeDetail?.level}
                  shoeClass={shoeDetail?.class}
                  durable={shoeDetail?.durable}
                  color={qualityColor}
                />
              </div>
            </div>
          </WrapperBgImg>
        </div>
      </>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={styles.wrapper}>
        <>
          <div className='df jcb'>
            <div css={styles.modalTitle}>Matching shoes</div>
            <div className='pointer' onClick={handleClose}>
              <Images.CloseIcon />
            </div>
          </div>

          {shoeView ? (
            renderShoeDetail(shoeView)
          ) : (
            <>
              <div css={styles.noSelect}>
                <img src={Images.EmptyShoe} alt='' />
              </div>
              <p css={styles.noSelectText}>Select sneakers to mint!</p>
            </>
          )}

          <div css={styles.shoeList}>
            {shoeList.map((item) => (
              <div
                css={[
                  styles.shoeItem,
                  item.shoeId === shoeView?.shoeId ? styles.shoeItemActive : '',
                ]}
                key={item.shoeId}
                onClick={() => setShoeView(item)}
              >
                <img src={item.image} alt='' />
              </div>
            ))}
          </div>

          <div css={styles.actions} className='mt-5 mx-auto'>
            <Button
              css={[commonClass.outlineButton, styles.cancelBtn]}
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                if (shoeView) onSuccess(shoeView)
              }}
              css={[commonClass.appButton, styles.confirmBtn]}
              disabled={loading}
            >
              {!!loading && <CircularProgress size={18} />}
              CONFIRM
            </Button>
          </div>
        </>
      </Box>
    </Modal>
  )
}

export default SelectShoeModal
