/** @jsxImportSource @emotion/react */
import { Button, Slider } from '@mui/material'
import { useEffect, useState } from 'react'
import { baseColors, commonClass } from 'theme'
import { MARKET_TABS, NFT_TYPE } from 'types/common'
import styles from './FilterMenu.styles'

export interface FilterMenuSelectProps {
  title: string
  selectList: Array<any>
  value?: string
  onChange: Function
}

export interface FilterMenuSlideProps {
  title: string
  value: [number, number]
  min: number
  max: number
  step: number
  onChange: Function
}

export interface IFilterData {
  type?: string
  class?: string
  quality?: string
  stoneType?: string
  size?: string
  level?: [number, number]
  mint?: [number, number]
  lifeTime?: [number, number]
  stoneQuality?: [number, number]
}

export interface FilterMenuProps {
  onSave: Function
  onChange: Function
  value?: IFilterData
  type?: string
}

const FilterMenuSelect = ({
  selectList,
  value,
  onChange,
  title,
}: FilterMenuSelectProps) => {
  return (
    <div css={styles.item}>
      <p css={styles.label}>{title}</p>
      <div css={styles.selectList}>
        {selectList.map((item) => (
          <div
            key={item.key}
            css={[
              styles.selectItem,
              item.key === value
                ? item.color
                  ? `background: ${item.color}`
                  : styles.selectItemActive
                : null,
              item.color ? `border: 1px solid ${item.color}` : null,
            ]}
            onClick={() => onChange(item.key)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  )
}

const FilterMenuSlide = ({
  value,
  onChange,
  title,
  min,
  max,
  step,
}: FilterMenuSlideProps) => {
  const [v, setV] = useState<[number, number]>(value)

  return (
    <div css={styles.item}>
      <p css={styles.label}>{title}</p>
      <div css={styles.slider}>
        <div css={styles.minMax}>
          <p>{min}</p>
          <p>{max}</p>
        </div>
        <Slider
          value={v}
          onChange={(e: any) => {
            if (e.target.value) {
              setV(e.target.value || [0, 0])
            }
          }}
          onChangeCommitted={() => {
            onChange(v)
          }}
          valueLabelDisplay='auto'
          min={min}
          max={max}
          step={step}
          size='small'
        />
      </div>
    </div>
  )
}

const FilterMenu = ({ onSave, value, onChange, type }: FilterMenuProps) => {
  const [data, setData] = useState<IFilterData>({ ...value })
  const count = Object.values(data).filter((a) => a).length

  useEffect(() => {
    onChange(data)
  }, [data])

  const renderFilterShoe = () => {
    return (
      <div css={styles.content}>
        <FilterMenuSelect
          title='Types'
          selectList={[
            {
              key: NFT_TYPE.SHOE,
              label: 'Sneakers',
            },
            {
              key: NFT_TYPE.SHOEBOX,
              label: 'Shoeboxes',
            },
          ]}
          value={data.type}
          onChange={(e: string) => {
            setData({ type: data.type === e ? '' : e })
          }}
        />
        {data.type === NFT_TYPE.SHOE && (
          <FilterMenuSelect
            title='Classes'
            selectList={[
              {
                key: 'Walker',
                label: 'Walker',
              },
              {
                key: 'Jogger',
                label: 'Jogger',
              },
              {
                key: 'Runner',
                label: 'Runner',
              },
              {
                key: 'Trainer',
                label: 'Trainer',
              },
            ]}
            value={data.class}
            onChange={(e: string) => {
              setData({ ...data, class: data.class === e ? '' : e })
            }}
          />
        )}
        {data.type && (
          <FilterMenuSelect
            title='Quality'
            selectList={[
              {
                key: 'Common',
                label: 'Common',
                color: baseColors.CommonColor,
              },
              {
                key: 'Uncommon',
                label: 'Uncommon',
                color: baseColors.UncommonColor,
              },
              {
                key: 'Rare',
                label: 'Rare',
                color: baseColors.RareColor,
              },
              {
                key: 'Epic',
                label: 'Epic',
                color: baseColors.EpicColor,
              },
              {
                key: 'Legendary',
                label: 'Legendary',
                color: baseColors.LegendaryColor,
              },
            ]}
            value={data.quality}
            onChange={(e: string) => {
              setData({ ...data, quality: data.quality === e ? '' : e })
            }}
          />
        )}

        {data.type === NFT_TYPE.SHOE && (
          <FilterMenuSlide
            title='Level'
            min={0}
            max={30}
            step={1}
            value={data.level || [0, 30]}
            onChange={(e: [number, number]) => {
              setData({ ...data, level: e })
            }}
          />
        )}
        {data.type === NFT_TYPE.SHOE && (
          <FilterMenuSlide
            title='Shoe mint'
            min={0}
            step={1}
            max={7}
            value={data.mint || [0, 7]}
            onChange={(e: [number, number]) => {
              setData({ ...data, mint: e })
            }}
          />
        )}
      </div>
    )
  }

  const renderFilterStone = () => {
    return (
      <div css={styles.content}>
        <FilterMenuSelect
          title='Type'
          selectList={[
            {
              key: 'Performance',
              label: 'Performance',
              color: baseColors.performanceColor,
            },
            {
              key: 'Recovery',
              label: 'Recovery',
              color: baseColors.recoveryColor,
            },
            {
              key: 'Speed',
              label: 'Speed',
              color: baseColors.speedColor,
            },
            {
              key: 'Luck',
              label: 'Luck',
              color: baseColors.luckColor,
            },
          ]}
          value={data.stoneType}
          onChange={(e: string) => {
            setData({ ...data, stoneType: data.stoneType === e ? '' : e })
          }}
        />

        <FilterMenuSlide
          title='Quality'
          min={1}
          max={9}
          step={1}
          value={data.stoneQuality || [1, 9]}
          onChange={(e: [number, number]) => {
            setData({ ...data, stoneQuality: e })
          }}
        />
      </div>
    )
  }

  const renderFilterMana = () => {
    return (
      <div css={styles.content}>
        <FilterMenuSelect
          title='Size'
          selectList={[
            {
              key: 'SMALL',
              label: 'SMALL',
            },
            {
              key: 'MEDIUM',
              label: 'MEDIUM',
            },
            {
              key: 'LARGE',
              label: 'LARGE',
            },
          ]}
          value={data.size}
          onChange={(e: string) => {
            setData({ size: data.size === e ? '' : e })
          }}
        />
        <FilterMenuSelect
          title='Quality'
          selectList={[
            {
              key: 'Common',
              label: 'Common',
              color: baseColors.CommonColor,
            },
            {
              key: 'Uncommon',
              label: 'Uncommon',
              color: baseColors.UncommonColor,
            },
            {
              key: 'Rare',
              label: 'Rare',
              color: baseColors.RareColor,
            },
            {
              key: 'Epic',
              label: 'Epic',
              color: baseColors.EpicColor,
            },
            {
              key: 'Legendary',
              label: 'Legendary',
              color: baseColors.LegendaryColor,
            },
          ]}
          value={data.quality}
          onChange={(e: string) => {
            setData({ ...data, quality: data.quality === e ? '' : e })
          }}
        />
      </div>
    )
  }

  return (
    <div css={styles.wrapper}>
      <p css={styles.title}>
        Filter <span>{count}</span>
      </p>

      {type === MARKET_TABS.SHOE && renderFilterShoe()}
      {type === MARKET_TABS.STONE && renderFilterStone()}
      {type === MARKET_TABS.MANA && renderFilterMana()}

      <div css={styles.actions}>
        <Button
          css={[commonClass.outlineButton, styles.actionClear]}
          onClick={() => setData({})}
        >
          CLEAR FILTER
        </Button>
        <Button
          css={[commonClass.appButton, styles.actionSave]}
          onClick={() => onSave(data)}
        >
          CONFIRM
        </Button>
      </div>
    </div>
  )
}

export default FilterMenu
