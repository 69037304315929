/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { IconButton, Avatar } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import _startsWith from 'lodash/startsWith'
import Images from 'images'
import { CUSTOM_EVENTS } from 'types/common'
import { useAppDispatch, useAppSelector } from 'store'
import ModalLogin from 'components/login-modal'
import { ROUTE_PATHS, useRouteMetaData } from 'routes'
import { toFixedNumber } from 'helpers/utils'
import { commonClass } from 'theme'
import { fetchWalletBallance } from 'store/reducers/wallet'
import UserMenu from './UserMenu'
import styles from './Header.styles'

const Header = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const routeMetaData = useRouteMetaData()
  const walletBallance = useAppSelector((state) => state.wallet)
  const [openMenu, setOpenMenu] = useState(false)
  const handleOpenUserMenu = () => {
    setOpenMenu(true)
  }

  const handleCloseUserMenu = () => {
    setOpenMenu(false)
  }

  const handleRefresh = () => {
    dispatch(fetchWalletBallance({}))
    document.dispatchEvent(new Event(CUSTOM_EVENTS.REFRESH_CLICKED))
  }
  const accessToken = useAppSelector((state) => state.auth.token)
  const runLogin = () => {
    openLogin(true)
  }
  const [isLogin, openLogin] = useState(false)

  useEffect(() => {
    if (accessToken) {
      openLogin(false)
      document.dispatchEvent(new Event(CUSTOM_EVENTS.REFRESH_CLICKED))
    } else {
      openLogin(true)
    }
  }, [accessToken])

  const handleBackClick = () => {
    const navigateUrl: any = routeMetaData?.backUrl || -1

    navigate(navigateUrl)
  }

  const menuNavs = [
    {
      icon: Images.MarketIcon,
      activeIcon: Images.MarketActiveIcon,
      label: 'Market Place',
      url: ROUTE_PATHS.MARKET,
    },
    {
      icon: Images.BagIcon,
      activeIcon: Images.BagActiveIcon,
      label: 'Bag',
      url: ROUTE_PATHS.BAG,
    },
  ]

  const isMatchPath = (path: string) => {
    return _startsWith(location.pathname, path)
  }

  if (!!routeMetaData?.noHeader) {
    return null
  }

  return (
    <div css={styles.headerStyles}>
      <div css={styles.wrapper}>
        <div css={styles.logo}>
          {!!routeMetaData?.back && (
            <Images.BackIcon className='pointer' onClick={handleBackClick} />
          )}
          <Link to={ROUTE_PATHS.MARKET} css={commonClass.hideInMobile}>
            <img height={40} src={Images.Logo} alt='' />
          </Link>
        </div>
        <div css={styles.menus}>
          {menuNavs.map((menuItem) => (
            <div
              key={menuItem.label}
              css={[
                styles.menuItem,
                isMatchPath(menuItem.url) ? styles.menuItemActive : '',
              ]}
              onClick={() => navigate(menuItem.url)}
              className={isMatchPath(menuItem.url) ? 'active' : ''}
            >
              <img
                src={
                  isMatchPath(menuItem.url)
                    ? menuItem.activeIcon
                    : menuItem.icon
                }
                alt={menuItem.label}
              />
            </div>
          ))}
        </div>

        <div css={styles.profile}>
          <div css={styles.walletInfo}>
            <div css={styles.walletInfoItem}>
              <div css={styles.tokenIcon}>
                <img src={Images.FitTokenIcon} alt='fit token' />
              </div>

              <span>{toFixedNumber(walletBallance.fit)}</span>
            </div>
            <div css={styles.walletInfoItem}>
              <div css={styles.tokenIcon}>
                <img src={Images.CaloTokenIcon} alt='calo token' />
              </div>

              <span>{toFixedNumber(walletBallance.calo)}</span>
            </div>
          </div>

          <IconButton onClick={handleRefresh}>
            <Images.RefreshIcon />
          </IconButton>
          <div css={styles.avatar}>
            {accessToken ? (
              <Avatar
                src='/assets/images/avatar.png'
                onClick={handleOpenUserMenu}
              />
            ) : (
              <p onClick={() => runLogin()} css={styles.loginText}>
                Login
              </p>
            )}
          </div>
        </div>
      </div>

      <UserMenu isOpen={openMenu} handleClose={handleCloseUserMenu} />
      <ModalLogin
        open={isLogin}
        handleClose={() => {
          openLogin(false)
        }}
      />
    </div>
  )
}

export default Header
