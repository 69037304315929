import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
const TYPE = 'COMMON'

interface IToast {
  message?: string
  title: string
}
interface ICommonState {
  toast: IToast | null
}

const initialState: ICommonState = {
  toast: null,
}

const commonSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<IToast | null>) {
      state.toast = action.payload
    },
  },
})

export const { showToast } = commonSlice.actions
export default commonSlice.reducer
