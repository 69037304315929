import { css } from '@emotion/react'
import { baseColors, tabletWidth } from 'theme'

const styles = {
  headerStyles: css`
    padding: 0 16px;
    height: 60px;

    @media only screen and (min-width: ${tabletWidth}px) {
      box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
      position: relative;
      z-index: 1;
    }
  `,
  avatar: css`
    margin-left: 16px;
    cursor: pointer;
  `,
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  `,
  logo: css`
    gap: 15px;
    flex: 1;
    display: flex;
    align-items: center;
  `,
  profile: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  loginText: css`
    color: white;
    margin: 0;
    padding: 6px 24px;
    cursor: pointer;
  `,

  walletInfo: css`
    border-radius: 8px;
    padding: 4px;
    display: flex;
  `,
  walletInfoItem: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 0;
    margin-left: 8px;
    margin-right: 8px;
  `,
  tokenIcon: css`
    display: flex;
    align-items: center;
    img {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  `,

  menus: css`
    display: none;
    height: 100%;
    @media only screen and (min-width: ${tabletWidth}px) {
      display: flex;
    }
  `,
  menuItem: css`
    padding: 14px 60px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    &:hover {
      background: #fff1;
    }
    img {
      height: 24px;
      width: 24px;
    }
  `,
  menuItemActive: css`
    border-color: ${baseColors.primaryColor3};
  `,
}

export default styles
