/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import _get from 'lodash/get'
import _toLower from 'lodash/toLower'
import lottie from 'lottie-web'
import Images from 'images'
import { baseColors, commonClass } from 'theme'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { IShoe, IShoeBox } from 'types/nft'
import { fetchOpenShoebox, fetchShoeDetail } from 'store/reducers/bag'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { capitalizeFirstLetter } from 'helpers/utils'
import AdvanceInfo from 'components/advance-info/AdvanceInfo'
import animations from 'animations'
import styles from './ShoeboxOpen.styles'
interface IProps {
  isOpen: boolean
  handleClose: () => void
  itemDetail: IShoeBox | null | undefined
  onOpenSuccess: () => void
}

const ShoeboxOpen = ({
  isOpen,
  handleClose,
  itemDetail,
  onOpenSuccess,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const [result, setResult] = useState<IShoe | undefined>()
  const animationWrapperRef = useRef(null)
  const shoeboxImg = (Images.shoebox as any)[
    _toLower(itemDetail?.quality) || 'common'
  ] as string

  useEffect(() => {
    if (!isOpen) {
      setLoading(false)
      setStartAnimation(false)
    }
    setResult(undefined)
  }, [isOpen])

  const handleConfirm = () => {
    setLoading(true)
    const shoeDetail = itemDetail

    if (shoeDetail?.shoeBoxId) {
      dispatch(
        fetchOpenShoebox({
          shoeBoxId: shoeDetail.shoeBoxId,
        }),
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.openedShoeId) {
            dispatch(fetchShoeDetail({ shoeId: response.openedShoeId }))
              .then(unwrapResult)
              .then((r) => {
                setResult(r.response)
                setStartAnimation(true)
              })
          } else {
            dispatch(
              showToast({
                title: 'Error',
                message: response.message,
              }),
            )
            setLoading(false)
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const renderAnimation = () => {
    return (
      <div css={styles.animationWrap}>
        <img className='box-img' src={shoeboxImg} alt='' />
        <img className='shoe-img' src={result?.image} alt='' />
        <div ref={animationWrapperRef} className='svg-wrapper'></div>
      </div>
    )
  }

  useEffect(() => {
    if (animationWrapperRef.current && startAnimation) {
      setTimeout(() => {
        lottie.loadAnimation({
          container: animationWrapperRef.current as unknown as HTMLElement, // the dom element that will contain the animation
          renderer: 'canvas',
          loop: true,
          autoplay: true,
          animationData: animations.openShoe,
        })
        setTimeout(() => {
          setLoading(false)
        }, 500)
        setTimeout(() => {
          setStartAnimation(false)
        }, 6000)
      }, 3000)
    }
  }, [animationWrapperRef, startAnimation])

  const renderResult = () => {
    const qualityBg = (Images.qualityBg as any)[
      _toLower(result?.quality) || 'common'
    ] as string

    const qualityText = capitalizeFirstLetter(result?.quality)
    const qualityColor = _get(baseColors, qualityText + 'Color')

    if (!result) {
      return <div />
    }

    return (
      <>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Sneaker Detail</div>
          <div className='pointer' onClick={() => onOpenSuccess()}>
            <Images.CloseIcon />
          </div>
        </div>

        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img css={styles.shoeImg} src={result?.image} alt='' />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{result?.shoeId}
              </div>

              <div className='mb-3 mx-auto' css={styles.shoeInfoWrapper}>
                <ShoeInfo
                  className='mx-auto'
                  level={result?.level}
                  shoeClass={result?.class}
                  durable={result?.durable}
                  color={qualityColor}
                />
              </div>
            </div>
          </WrapperBgImg>
        </div>
        <AdvanceInfo detail={result} />
      </>
    )
  }

  const renderConfirm = () => {
    return (
      <>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Confirm to open</div>
          <div className='pointer' onClick={handleClose}>
            <Images.CloseIcon />
          </div>
        </div>
        <p>Are you sure you want to open this shoebox? </p>

        <div css={styles.actions} className='mt-5 mx-auto'>
          <Button
            css={[commonClass.outlineButton, styles.cancelBtn]}
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            onClick={handleConfirm}
            css={[commonClass.appButton, styles.confirmBtn]}
            disabled={loading}
          >
            {!!loading && <CircularProgress size={18} />}
            CONFIRM
          </Button>
        </div>
      </>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        {startAnimation && renderAnimation()}
        {!startAnimation && (
          <Box css={styles.wrapper}>
            {result ? renderResult() : renderConfirm()}
          </Box>
        )}
      </>
    </Modal>
  )
}

export default ShoeboxOpen
