/** @jsxImportSource @emotion/react */
import { Modal, Box, Button, CircularProgress } from '@mui/material'
import _toLower from 'lodash/toLower'
import _PadStart from 'lodash/padStart'
import _get from 'lodash/get'
import { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Images from 'images'
import WrapperBgImg from 'components/wrap-background-img/WrapperBgImg'
import { baseColors, commonClass } from 'theme'
import { capitalizeFirstLetter, toFixedNumber } from 'helpers/utils'
import ShoeInfo from 'components/shoe-info/ShoeInfo'
import { useAppDispatch } from 'store'
import { showToast } from 'store/reducers/common'
import { IShoe } from 'types/nft'
import ProgressBar from 'components/progress-bar/ProgressBar'
import { boostShoeTime, upgradeShoe } from 'store/reducers/bag'
import { fetchWalletBallance } from 'store/reducers/wallet'
import styles from './LevelUpModal.styles'
interface IProps {
  isOpen: boolean
  onLevelUpSuccess: () => void
  shoeDetailData: IShoe | null
  loadDetail: () => void
  setOpen: (isOpen: boolean) => void
}
export const getReturnCowndownValues = (
  countDown: number,
  totalWaitTime: number,
) => {
  if (!countDown) {
    return { hours: '00', minutes: '00', seconds: '00', percent: 0 }
  }
  // calculate time left
  const hours = Math.floor(countDown / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return {
    hours: _PadStart(Math.max(0, hours) + '', 2, '0'),
    minutes: _PadStart(Math.max(0, minutes) + '', 2, '0'),
    seconds: _PadStart(Math.max(0, seconds) + '', 2, '0'),
    percent: Math.min(
      Math.max(
        Math.floor(((totalWaitTime - countDown) / totalWaitTime) * 100),
        0,
      ),
      100,
    ),
  }
}
const states = {
  CONFIRM_LEVEL_UP: 1,
  INPROGRESS_LEVEL_UP: 2,
  CONFIRM_BOOST: 3,
}
const LevelUpModal = ({
  isOpen,
  shoeDetailData,
  setOpen,
  onLevelUpSuccess,
  loadDetail,
}: IProps) => {
  const dispatch = useAppDispatch()

  const [pageState, setPageState] = useState(states.CONFIRM_LEVEL_UP)
  const [finishDate, setFinishDate] = useState(0)
  const [countDown, setCountDown] = useState(0)
  const qualityText = capitalizeFirstLetter(shoeDetailData?.quality)
  const qualityColor = _get(baseColors, qualityText + 'Color')
  const qualityBg = (Images.qualityBg as any)[
    _toLower(shoeDetailData?.quality) || 'common'
  ] as string
  const levelValue = shoeDetailData?.level || 0
  const upgradeCost = shoeDetailData?.upgradeCost
  const upgradeCalo = _get(shoeDetailData, 'upgradeCalo')

  const levelingTime = shoeDetailData?.upgradeLevelingTime || 0
  const speedUpCost = shoeDetailData?.upgradeSpeedUpCost || 0
  const levelUpTime = shoeDetailData?.levelUpTime || 0
  const countDownObj = getReturnCowndownValues(countDown, levelingTime * 60000)

  useEffect(() => {
    setBoosting(false)
    if (levelUpTime) {
      setPageState(states.INPROGRESS_LEVEL_UP)
      setFinishDate(new Date(levelUpTime).getTime())
    } else {
      setPageState(states.CONFIRM_LEVEL_UP)
    }
  }, [levelUpTime, isOpen])

  useEffect(() => {
    let interval: any

    if (finishDate) {
      setCountDown(finishDate - Date.now())
      interval = setInterval(() => {
        setCountDown(finishDate - Date.now())
      }, 1000)
    } else {
      setCountDown(0)
    }

    return () => clearInterval(interval)
  }, [finishDate])

  const closeModal = () => {
    if (countDownObj.percent === 100) {
      loadDetail()
    }
    setOpen(false)
  }

  const onClickCancel = () => {
    if (pageState === states.CONFIRM_BOOST) {
      setPageState(states.INPROGRESS_LEVEL_UP)

      return
    }
    closeModal()
  }
  const onConfirmLevelUp = () => {
    if (!shoeDetailData?.shoeId) return
    setBoosting(true)
    dispatch(
      upgradeShoe({
        shoeId: shoeDetailData.shoeId,
      }),
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          dispatch(fetchWalletBallance({}))
          loadDetail()
          // setBoosting(false);
        } else {
          dispatch(
            showToast({
              title: response.message,
            }),
          )
          setBoosting(false)
        }
      })
      .catch(() => {
        setBoosting(false)
      })
  }

  const onConfirmBoost = () => {
    if (!shoeDetailData?.shoeId) return
    setBoosting(true)
    dispatch(
      boostShoeTime({
        shoeId: shoeDetailData.shoeId,
      }),
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          dispatch(fetchWalletBallance({}))
          loadDetail()
          closeModal()
          setTimeout(() => {
            onLevelUpSuccess()
          }, 500)
        } else {
          dispatch(
            showToast({
              title: response.message,
            }),
          )
        }
      })
      .finally(() => {
        setBoosting(false)
      })
  }

  const [boosting, setBoosting] = useState(false)

  return (
    <Modal open={isOpen}>
      <Box css={styles.wrapper}>
        <div className='df jcb'>
          <div css={styles.modalTitle}>Level Up</div>
          <div className='pointer' onClick={closeModal}>
            <Images.CloseIcon />
          </div>
        </div>
        <div css={styles.bgWrapper}>
          <WrapperBgImg src={qualityBg}>
            <div css={styles.bgWrapperContent}>
              <img css={styles.shoeImg} src={shoeDetailData?.image} alt='' />

              <div
                className='mb-2'
                css={styles.shoeId}
                style={{ color: qualityColor }}
              >
                #{shoeDetailData?.shoeId}
              </div>

              <div className='mb-3 mx-auto' css={styles.shoeInfoWrapper}>
                <ShoeInfo
                  className='mx-auto'
                  level={shoeDetailData?.level}
                  shoeClass={shoeDetailData?.class}
                  durable={shoeDetailData?.durable}
                  color={qualityColor}
                />
              </div>
            </div>
          </WrapperBgImg>
        </div>

        <div css={styles.detailWrap}>
          {pageState === states.CONFIRM_LEVEL_UP && (
            <div css={styles.infoWrap}>
              <div css={styles.confirmInfoItem}>
                <span>Level up to</span>
                <span>Level {levelValue + 1}</span>
              </div>
              <div css={styles.confirmInfoItem}>
                <span>Cost</span>
                <span>
                  {toFixedNumber(upgradeCost)} FIT
                  {upgradeCalo > 0
                    ? ` + ${toFixedNumber(upgradeCalo)} CALO`
                    : ''}
                </span>
              </div>
              <div css={styles.confirmInfoItem}>
                <span>Time</span>
                <span>{levelingTime} Mins</span>
              </div>
            </div>
          )}

          {pageState === states.INPROGRESS_LEVEL_UP && (
            <div css={styles.infoWrap}>
              <div css={styles.confirmInfoItem}>
                <span>Level up to</span>
                <span>Level {levelValue + 1}</span>
              </div>
              <div css={[styles.infoRow]}>
                <span>{countDownObj.percent}%</span>
                <ProgressBar value={countDownObj.percent} maxValue={100} />
              </div>
              <div css={[styles.confirmInfoItem]} className='mt-1'>
                <span>Remaining time</span>
                <span>
                  {countDownObj.hours}:{countDownObj.minutes}:
                  {countDownObj.seconds}
                </span>
              </div>
            </div>
          )}

          {pageState === states.CONFIRM_BOOST && (
            <div css={styles.infoWrap}>
              <div css={[styles.infoRow]}>
                <span>To boost Leveling up</span>
              </div>
              <div className='df aic jcb' css={styles.infoRow}>
                <span>Cost</span>
                <div className='df aic '>
                  <span>
                    {toFixedNumber(
                      Math.max(
                        Math.floor((100 - countDownObj.percent) * speedUpCost) /
                          100,
                        0,
                      ),
                    )}{' '}
                    FIT
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

        {pageState === states.INPROGRESS_LEVEL_UP ? (
          <div className='dfc' css={styles.actions}>
            <Button
              key={'BOOST'}
              onClick={() => {
                setBoosting(false)
                setPageState(states.CONFIRM_BOOST)
              }}
              css={[commonClass.appButton, styles.confirmBtn]}
              disabled={countDownObj.percent === 100}
            >
              BOOST
            </Button>
          </div>
        ) : (
          <div css={styles.actions}>
            <Button
              key={'cancel'}
              onClick={() => onClickCancel()}
              css={[commonClass.outlineButton, styles.confirmBtn]}
            >
              CANCEL
            </Button>

            {pageState === states.CONFIRM_LEVEL_UP && (
              <Button
                css={[commonClass.appButton, styles.confirmBtn]}
                onClick={() => onConfirmLevelUp()}
                disabled={boosting}
                // loading={boosting}
              >
                {!!boosting && <CircularProgress size={18} />}
                CONFIRM
              </Button>
            )}
            {pageState === states.CONFIRM_BOOST && (
              <Button
                key={'CONFIRM'}
                css={[commonClass.appButton, styles.confirmBtn]}
                onClick={() => {
                  onConfirmBoost()
                }}
                disabled={boosting || countDownObj.percent === 100}
                // loading={boosting}
              >
                {!!boosting && <CircularProgress size={18} />}
                CONFIRM
              </Button>
            )}
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default LevelUpModal
