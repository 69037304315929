/** @jsxImportSource @emotion/react */
import { Drawer } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Images from 'images'
import { ROUTE_PATHS } from 'routes'
import styles from './ModalSelectTokens.styles'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

export const tokenList = [
  {
    name: 'BNB',
    type: 'BNB',
    icon: Images.BnbTokenIcon,
  },
  {
    name: 'CALO',
    type: 'CALO',
    icon: Images.CaloTokenIcon,
  },
  {
    name: 'FIT',
    type: 'FIT',
    icon: Images.FitTokenIcon,
  },
]

const ModalSelectToken = ({ isOpen, handleClose }: IProps) => {
  const navigate = useNavigate()

  const handleItemClick = (tokenType: string) => {
    navigate(
      ROUTE_PATHS.WALLET_TRANSFER_EXTERNAL.replace(':tokenType', tokenType),
    )
  }

  return (
    <Drawer anchor='bottom' open={isOpen} onClose={handleClose}>
      <div css={styles.menuWrapper}>
        {tokenList.map((token) => (
          <div
            css={styles.tokenItem}
            onClick={() => handleItemClick(token.type)}
          >
            <img css={styles.tokenIcon} src={token.icon} alt='token-icon' />
            <span css={styles.tokenName}>{token.name}</span>
          </div>
        ))}
      </div>
    </Drawer>
  )
}

export default ModalSelectToken
